<template>
    <v-app>
      <v-card flat color="orange darken-4">
        <v-toolbar
          color="orange darken-4"
          flat>
          <v-toolbar-title class="text-h5 white--text">
              <h3>Order, Drafts and Prescription list</h3>
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <template v-slot:extension>
            <v-tabs
              dark
              v-model="tab"
              center-active>
              <v-tabs-slider color="yellow darken-2"></v-tabs-slider>
              <v-tab key="transaction">Transactions ({{orderPendingCount}})</v-tab>
              <v-tab key="quotation">Drafts ({{quotationPendingCount}})</v-tab>
              <v-tab key="prescription">Prescriptions ({{prescriptionCount}})</v-tab>
            </v-tabs>
          </template>
        </v-toolbar> 
        <v-tabs-items v-model="tab" class="px-4 py-4">
          <v-tab-item key="transaction">
            <v-data-table
              :headers="headers"
              :items="orders"
              :search="search"
              item-key="orderId"
              :item-class="itemRowBackground"
              >
              <template v-slot:top>
                  <v-text-field 
                    solo 
                    v-model="search" 
                    label="Search transactions" 
                    single-line 
                    clearable
                    clear-icon="mdi-close-circle"
                    color="orange darken-2"
                    prepend-inner-icon="mdi-magnify"
                    hide-details>
                  </v-text-field>
              </template>
              <template v-slot:item.detailAction="{item}">
                <v-btn 
                  text
                  color="grey darken-4"
                  v-if="item.orderStatusCode == -1"
                  @click="getOrderInformation(item)">
                    <v-icon>mdi-information</v-icon>
                </v-btn>
                 <v-btn 
                  text
                  color="orange darken-4"
                  v-else
                  @click="getOrderInformation(item)">
                    <v-icon>mdi-information</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.totalAmount="{item}">
                {{getCurrency(item.totalAmount)}}
              </template>
              <template v-slot:item.created="{item}">
                {{getDate(item.created)}}
              </template>
              <template v-slot:item.orderStatusCode="{item}" v-if="hasAccess">
                <v-icon 
                  color="orange darken-4"
                  left
                  v-if="getOrderStatus(item.orderStatusCode) === 'APPROVED'">
                  mdi-check-circle
                </v-icon>
                <span
                  class="orange--text text--darken-4"
                  v-if="getOrderStatus(item.orderStatusCode) === 'APPROVED'">
                  Completed
                </span>
                <v-icon 
                  color="grey darken-2"
                  left
                  v-if="getOrderStatus(item.orderStatusCode) === 'CANCELLED'">
                  mdi-close-thick
                </v-icon>
                <span
                  class="grey--text text--darken-2"
                  v-if="getOrderStatus(item.orderStatusCode) === 'CANCELLED'">
                  {{item.remarks}}
                </span>
                <v-btn
                  dark
                  color="orange darken-3"
                  class="mr-2"
                  @click="updateStatus(item, 1)"
                  v-if="getOrderStatus(item.orderStatusCode) === 'PENDING'">
                  <span>
                    Approve
                  </span>
                </v-btn>
                <v-btn
                  dark
                  color="yellow darken-3"
                  @click="showCancelPrompt(item, true)"
                  v-if="getOrderStatus(item.orderStatusCode) === 'PENDING'">
                  <span>
                    Cancel
                  </span>
                </v-btn>
              </template>
              <template v-slot:item.orderStatusCode="{item}" v-else>
                  <v-icon 
                    color="orange darken-4"
                    left
                    v-if="getOrderStatus(item.orderStatusCode) === 'APPROVED'">
                    mdi-check-circle
                  </v-icon>
                  <span
                    class="orange--text text--darken-4"
                    v-if="getOrderStatus(item.orderStatusCode) === 'APPROVED'">
                    Completed
                  </span>
                  <v-icon 
                    color="grey darken-2"
                    left
                    v-if="getOrderStatus(item.orderStatusCode) === 'CANCELLED'">
                    mdi-close-thick
                  </v-icon>
                  <span
                    class="grey--text text--darken-2"
                    v-if="getOrderStatus(item.orderStatusCode) === 'CANCELLED'">
                    {{item.remarks}}
                  </span>
                  <span 
                    class="yellow--text text--darken-4"
                    v-if="getOrderStatus(item.orderStatusCode) === 'PENDING'">
                    Processing
                  </span>
              </template>
              <template v-slot:item.actions="{item}">
                  <v-btn
                    text
                    disabled
                    v-if="item.orderStatusCode == -1"
                    @click="printOrder(item)">
                    <v-icon>mdi-printer</v-icon>
                  </v-btn>
                  <v-btn
                    text
                    color="orange darken-4"
                    v-else
                    @click="printOrder(item)">
                    <v-icon>mdi-printer</v-icon>
                  </v-btn>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item key="quotation">
            <v-data-table
              :headers="quotationHeader"
              :items="quotations"
              :search="searchQuotation"
              item-key="orderId"
              >
              <template v-slot:top>
                  <v-text-field 
                    solo 
                    v-model="search" 
                    label="Search quotations" 
                    single-line 
                    clearable
                    clear-icon="mdi-close-circle"
                    color="orange darken-2"
                    prepend-inner-icon="mdi-magnify"
                    hide-details>
                  </v-text-field>
              </template>
              <template v-slot:item.detailAction="{item}">
                <v-btn 
                  text
                  color="grey darken-4"
                  v-if="item.orderStatusCode == -1"
                  @click="getDraftInformation(item)">
                    <v-icon>mdi-information</v-icon>
                </v-btn>
                 <v-btn 
                  text
                  color="orange darken-4"
                  v-else
                  @click="getDraftInformation(item)">
                    <v-icon>mdi-information</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.totalAmount="{item}">
                {{getCurrency(item.totalAmount)}}
              </template>
              <template v-slot:item.created="{item}">
                {{getDate(item.created)}}
              </template>
               <template v-slot:item.orderStatusCode="{item}">
                <v-icon 
                  color="orange darken-4"
                  left
                  v-if="getOrderStatus(item.orderStatusCode) === 'APPROVED'">
                  mdi-check-circle
                </v-icon>
                 <span
                  class="orange--text text--darken-4"
                  v-if="getOrderStatus(item.orderStatusCode) === 'APPROVED'">
                  Moved to order
                </span>
                <v-icon 
                  left
                  color="grey darken-2"
                  v-if="getOrderStatus(item.orderStatusCode) === 'CANCELLED'">
                  mdi-close-thick
                </v-icon>
                <span
                  class="grey--text text--darken-2"
                  v-if="getOrderStatus(item.orderStatusCode) === 'CANCELLED'">
                  {{item.remarks}}
                </span>
                <span
                  v-if="getOrderStatus(item.orderStatusCode) === 'PENDING'">
                  Floating
                </span>
              </template>
              <template v-slot:item.actions="{item}">
                  <v-btn
                    color="orange darken-4"
                    dark
                    class="mr-1"
                    v-if="getOrderStatus(item.orderStatusCode) === 'PENDING'"
                    @click="showQuotationConfirm(item)">
                    <v-icon left>mdi-arrow-right</v-icon>
                    <span>Proceed</span>
                  </v-btn>
                  <v-btn
                    color="orange darken-4"
                    text
                    class="mr-1"
                    v-if="getOrderStatus(item.orderStatusCode) === 'PENDING'"
                    @click="editOrder(item)">
                    <v-icon left>mdi-pen</v-icon>
                    <span>Edit</span>
                  </v-btn>
                  <v-btn
                    color="orange darken-4"
                    text
                    v-if="getOrderStatus(item.orderStatusCode) === 'PENDING'"
                    @click="showCancelPrompt(item, false)">
                    <v-icon left>mdi-close-thick</v-icon>
                    <span>Cancel</span>
                  </v-btn>
              </template>
            </v-data-table>
          </v-tab-item>
          <v-tab-item key="prescription">
              <v-data-table
                :headers="prescriptionHeader"
                :items="prescriptions"
                :item-class="prescriptionRowBackground"
                :items-per-page="15" >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="orange darken-4"
                      dark
                      class="mb-2"
                      @click="prescriptionDialog = true">
                      <v-icon left>mdi-plus</v-icon>
                      <span>New prescription</span>
                    </v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:item.detailAction="{item}">
                <v-btn 
                  text
                  color="orange darken-4"
                  v-if="hasAccess"
                  @click="getPrescriptionInformation(item)">
                    <v-icon>mdi-information</v-icon>
                </v-btn>
              </template>
              <template v-slot:item.remarks="{item}">
                <v-btn
                  color="yellow darken-2"
                  @click="showRemarks(item)">
                  Click to view remarks
                </v-btn>
              </template>
              <template v-slot:item.created="{item}">
                {{getDate(item.created)}}
              </template>
              <template v-slot:item.statusCode="{item}">
                  <v-icon 
                    color="orange darken-4"
                    left
                    v-if="getPrescriptionStatus(item.statusCode) === 'Finished'">
                    mdi-check-circle
                  </v-icon>
                  <span
                    class="orange--text text--darken-4"
                    v-if="getPrescriptionStatus(item.statusCode) === 'Finished'">
                    Finished
                  </span>
                  <v-btn
                    color="orange darken-4"
                    left
                    dark
                    v-if="getPrescriptionStatus(item.statusCode) === 'Unread'"
                    @click="markReadingAsRead(item)">
                    <v-icon>mdi-check</v-icon>
                    Mark as read
                  </v-btn>
                  <span 
                    class="yellow--text text--darken-4"
                    v-if="getPrescriptionStatus(item.statusCode) === 'For reading'">
                    For reading...
                  </span>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
      <!-- Loading Dialog -->
      <v-dialog
        v-model="isLoading"
        persisent
        width="300"
        >
        <v-card>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <h6 class="orange--text text--darken-2 text-light">Fetching order information...</h6>
                </v-col>
                <v-col cols="12">
                  <v-progress-circular 
                  :size="70"
                  indeterminate 
                  color="orange darken-4"></v-progress-circular>
                </v-col>
              </v-row>
            </v-container>
            
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="saveLoading"
        persisent
        width="200"
      >
        <v-card>
          <v-card-text>
            <v-container fluid>
              <v-row>
                <v-col cols="12">
                  <v-progress-circular 
                  :size="70"
                  indeterminate 
                  color="orange darken-4"></v-progress-circular>
                </v-col>
              </v-row>
            </v-container>
            
          </v-card-text>
        </v-card>
      </v-dialog>
      <!-- Quotation Edit Dialog -->
      <v-dialog
        v-model="quoteDetailsDialog"
        scrollable
        max-width="1028">
        <v-sheet>
          <v-app-bar
            dark
            flat
            color="orange darken-4">
            <v-btn icon dark @click="quoteDetailsDialog=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Draft details</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-app-bar>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
              </v-col>
              <v-col cols="12" class="pb-4">
                <v-data-table
                  :headers="quotationItemHeader"
                  :items="quotationItems"
                  item-key="itemId"
                  >
                  <template v-slot:item.amount="{item}">
                    {{getCurrency(item.amount)}}
                  </template>
                  <template v-slot:item.price="{item}">
                      <span>{{getCurrency(item.price)}}</span>
                  </template>
                  <template v-slot:item.actions="{item}">
                      <v-btn
                        color="orange darken-4"
                        text
                        @click="deleteQuotationDetails(item)">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                  </template>
                   <template v-slot:item.quantity="{item}">
                    <v-edit-dialog 
                      large
                      @open="editQuantity = item.quantity"
                      @save="updateDetailQuantity(item)">
                      {{item.quantity}}
                      <template v-slot:input>
                        <div class="mt-4 text-h6">
                          Update quantity
                        </div>
                        <v-text-field
                          outlined
                          v-model="editQuantity"
                          label="Enter quantity"
                          single-line
                          autofocus
                          counter
                          type="number"
                          color="orange darken-3">
                        </v-text-field>
                      </template>
                    </v-edit-dialog>
                  </template>

                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-dialog>
      <!-- Order/Draft Details Dialog -->
      <v-dialog
        v-model="detailsDialog"
        scrollable
        width="800">
        <v-sheet>
          <v-app-bar
            dark
            flat
            color="orange darken-4">
            <v-btn icon dark @click="detailsDialog=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Order details</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn v-if="isOrder" dark text @click="printDetails()">
                  Print
              </v-btn>
              <v-btn v-if="isDraft" dark text @click="printDraftDetails()">
                  Print
              </v-btn>
            </v-toolbar-items>
          </v-app-bar>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
              </v-col>
              <v-col cols="12" class="pb-4">
                <v-data-table 
                  :headers="orderDetailsHeader"
                  :items="orderDetails">
                  <template v-slot:item.price="{item}">
                      <span>{{getCurrency(item.price)}}</span>
                  </template>
                  <template v-slot:item.amount="{item}">
                      <span>{{getCurrency(getRowTotal(item))}}</span>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-dialog>
      <!-- Confirmation Dialog -->
      <v-dialog
        v-model="confirmDialog"
        persistent
        max-width="600"
      >
        <v-card>
          <v-card-title class="text-h5">
            {{confirmHeader}}
          </v-card-title>
          <v-card-text>
            {{confirmBody}}
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="orange darken-1"
              text
              @click="confirmDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              color="orange darken-1"
              text
              @click="proceedQuotation"
            >
              Proceed
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        v-model="prescriptionDialog"
        max-width="600">
         <v-sheet>
          <v-app-bar
            dark
            flat
            color="orange darken-4">
            <v-btn icon dark @click="prescriptionDialog=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Prescription</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-toolbar-items>
              <v-btn dark text @click="proceedPrescription()">
                  Add
              </v-btn>
            </v-toolbar-items>
          </v-app-bar>
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <v-combobox
                  v-model="socialWorkerName"
                  :items="socialWorkerNames"
                  auto-select-first
                  clearable
                  color="orange darken-4"
                  prepend-icon="mdi-account-box"
                  label="Social worker name">
                </v-combobox>
                <v-file-input 
                  prepend-icon="mdi-camera"
                  small-chips
                  multiple
                  label="Prescription image(s)"
                  v-model="fileNames"
                ></v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-dialog>
      <!-- Cancellation remarks dialog -->
      <v-dialog 
        v-model="remarksDialog" 
        persistent
        max-width="450">
        <v-sheet>
          <v-app-bar
            dark
            flat
            color="orange darken-4">
            <v-btn icon dark @click="remarksDialog=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Cancellation</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-app-bar>
          <v-container>
            <v-row>
              <v-col cols="12" class="pb-4">
                <v-text-field 
                  color="orange darken-2" 
                  clearable 
                  v-model="remarks"
                  label="Enter your remarks here"></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="4">
                  <v-btn 
                    @click="cancelTransaction"
                    dark>
                    Proceed
                  </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-dialog>
      <!-- Prescription view dialog -->
      <v-dialog
        v-model="readingDialog"
        persistent
        max-width="650">
        <v-card>
          <v-app-bar
            dark
            flat
            color="orange darken-4">
            <v-btn icon dark @click="readingDialog=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
          </v-app-bar>
          <v-window v-model="step">
            <v-window-item :value="1">
              <v-carousel
                :continuous="true"
                :show-arrows="true"
                height="600">
                  <v-carousel-item
                    v-for="(photos, i) in prescriptionAttachments"
                    :key="i"
                    :src="photos">
                    <v-btn
                      link
                      @click="goToImage(photos)">
                      View image
                    </v-btn>
                  </v-carousel-item>
              </v-carousel>
              <v-list two-line>
                <v-list-item>
                  <v-list-item-avatar>
                    <v-img src="https://cdn.vuetifyjs.com/images/john.png"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{selectedPrescription.socialWorkerName}}</v-list-item-title>
                    <v-list-item-subtitle>Uploader</v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-window-item>
            <v-window-item :value="2">
                <v-col>
                  <v-textarea
                    label="Enter your remarks"
                    v-model="prescriptionRemarks"
                    auto-grow
                    solo
                    rows="3"
                    row-height="25"
                    shaped
                  ></v-textarea>
                </v-col>
            </v-window-item>
          </v-window>
           <v-divider></v-divider>
            <v-card-actions>
              <v-btn
                :disabled="step === 1"
                text
                @click="step--"
              >
                Back
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                dark
                v-if="step === 1"
                color="orange darken-4"
                depressed
                @click="step++"
              >
                Next
              </v-btn>
              <v-btn
                dark
                v-else
                color="orange darken-4"
                depressed
                @click="markAsFinished"
              >
                Mark as finished
              </v-btn>
            </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        max-width="400"
        v-model="readingRemarksDialog">
        <v-sheet>
          <v-app-bar
            dark
            flat
            color="orange darken-4">
            <v-btn icon dark @click="readingRemarksDialog=false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title>Remarks</v-toolbar-title>
            <v-spacer></v-spacer>
          </v-app-bar>
          <v-container>
            <v-row>
              <v-col>
                <pre>{{selectedRemarks}}</pre>
              </v-col>
            </v-row>
          </v-container>
        </v-sheet>
      </v-dialog>
      <v-snackbar
        v-model="snackbar"
        timeout="4000"
        >
        {{snackbarText}}
      </v-snackbar>
      <div id="printForm" class="print-form">
        <v-divider></v-divider>
        <div style="display:inline-block;width:50%;">
          <h4>Reference number: {{selectedTransactionCode}}</h4>
        </div>
        <div style="display:inline-block;width:50%;">
          <h4>Order date: {{selectedOrderDate}}</h4>
        </div>
        <div>
          <h4>Customer name: {{selectedCustomerName}}</h4>
        </div>
        <v-divider class="my-2"></v-divider>
        <div>
          <template>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th v-for="header in printHeaders"
                        :key="header.text"
                        class="text-left">
                        {{header.text}}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="item in printItems"
                    :key="item.productName">
                    <td>
                      {{item.productName}}
                    </td>
                    <td>
                      {{item.unit}}
                    </td>
                    <td>
                      {{item.quantity}}
                    </td>
                    <td>
                      {{item.price}}
                    </td>
                    <td>
                      {{item.total}}
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </template>
        </div>
        <div>
            <h4 class="my-4">Total Amount: {{getCurrency(getTotalAmount)}}</h4>
        </div>
        <v-divider class="mb-5"></v-divider>
        <div style="display:inline-block;width:33%;">
          <p>Prepared by: </p>
          <h4>{{selectedSocialWorkerName}}</h4>
          <h5 style="margin-top:-10px;">Social Worker Name</h5>
        </div>
        
        <div style="display:inline-block;width:33%;">
          <p>Approved by: </p>
          <h4>{{selectedChiefHead}}</h4>
          <h5 style="margin-top:-10px;">CIU Chief/Head</h5>
        </div>
        
        <div style="display:inline-block;width:33%;">
          <p>Received by: </p>
          <h4>{{selectedCustomerName}}</h4>
          <h5 style="margin-top:-10px;">Client name</h5>
        </div>
      </div>
    </v-app>
    
</template>

<script>
import moment from 'moment'
import {firebaseApp, fv} from "../firebase/firebaseInit"
import printJS from "print-js"
let db = firebaseApp.firestore()
export default {
    created(){
      db.collection('orders')
        .onSnapshot((querySnapshot) => {
          var items = [];
          var previousOrderCount = this.orderPendingCount
          this.orderPendingCount = 0;
          querySnapshot.forEach((doc) => {
            const data = {
              orderId: doc.id,
              transactionCode: doc.data().transactionCode,
              socialWorkerName: doc.data().socialWorkerName,
              customerName: doc.data().customerName,
              chiefHead: doc.data().chiefHead,
              totalAmount: doc.data().totalAmount,
              created: doc.data().created,
              remarks: doc.data().remarks,
              orderStatusCode: doc.data().orderStatusCode
            }
            items.push(data);
            if(doc.data().orderStatusCode === 0){
              this.orderPendingCount += 1;
              if(previousOrderCount < this.orderPendingCount){
                this.showNotification("order")
              }
            }
          });
          this.orders = items.sort((i1,i2) => {
            if(i1["created"] < i2["created"]) return 1;
            if(i1["created"] > i2["created"]) return -1;
            return 0;
          });
        });
      db.collection('quotations')
        .onSnapshot((querySnapshot) => {
          var items = [];
          var previousQuotationCount = this.quotationPendingCount
          this.quotationPendingCount = 0;
          querySnapshot.forEach((doc) => {
            const data = {
              orderId: doc.id,
              transactionCode: doc.data().transactionCode,
              socialWorkerName: doc.data().socialWorkerName,
              customerName: doc.data().customerName,
              chiefHead: doc.data().chiefHead,
              totalAmount: doc.data().totalAmount,
              created: doc.data().created,
              remarks: doc.data().remarks,
              orderStatusCode: doc.data().statusCode
            }
            items.push(data);
            if(doc.data().statusCode === 0) {
              this.quotationPendingCount += 1;
              if(previousQuotationCount < this.quotationPendingCount){
                this.showNotification("quotation")
              }
            }
          });
          this.quotations = items.sort((i1,i2) => {
            if(i1["created"] < i2["created"]) return 1;
            if(i1["created"] > i2["created"]) return -1;
            return 0;
          });
        });
      db.collection('prescriptions').orderBy('created','desc')
        .onSnapshot((querySnapshot) => {
          var items = [];
          var previousPrescriptionCount = this.prescriptionCount
          this.prescriptionCount = 0;
          querySnapshot.forEach((doc) => {
            const data = {
              transactionId: doc.id,
              socialWorkerName: doc.data().socialWorkerName,
              created: doc.data().created,
              statusCode: doc.data().statusCode,
              remarks: doc.data().remarks,
              attachments: doc.data().attachments
            }
            items.push(data);
            if(doc.data().statusCode === 0) {
              this.prescriptionCount += 1;
              if(previousPrescriptionCount < this.prescriptionCount){
                this.showNotification("prescription")
              }
            }
            else if(doc.data().statusCode === 1){
              if(!this.hasAccess){
                this.prescriptionCount += 1;
              }
              if(previousPrescriptionCount < this.prescriptionCount){
                this.showWorkerNotification()
              }
            }
          });
          this.prescriptions = items;
        })
      db.collection('portalData').doc('---portaldata---')
        .onSnapshot((docSnapshot) => {
          this.socialWorkerNames = docSnapshot.data().socialWorker
        })
      
    },
    computed: {
      getTotalAmount(){
        var totalAmount = 0.0;
        this.printItems.forEach((item) => {
          totalAmount += parseFloat(item.total);
        });
        return totalAmount;
      },
      hasAccess(){
        return this.$store.state.isAdmin;
      }
    },
    methods: {
      async markReadingAsRead(item){
        var docRef = db.collection('prescriptions').doc(item.transactionId)
        docRef.update({
          'statusCode': 2
        }).then(() => {
          this.snackbar = true
          this.snackbarText = "Reading marked as read."
        })
      },
      async showWorkerNotification(){
        var title = "New reading"
        var message = "There is a new finished prescription reading"
        const notification = {
          title: title,
          options: {
            body: message
          },
          events:{
            onerror: function () {
            },
            onclick: function () {
            },
            onclose: function () {
            },
            onshow: function () {
            }
          }
        }
        this.$notification.requestPermission().then((permission) => {
          if(permission == 'granted' || permission == 'default'){
            this.$notification.show(notification.title, notification.options, notification.events);
          }
        })
      },
      async showNotification(notificationType){
        if(!this.hasAccess){
          return
        }
        //Order, quotation and prescription
        var title = "New order"
        var message = "There is a new order"
        if(notificationType == "quotation"){
          title = "New quotation"
          message = "There is a new quotation"
        }
        if(notificationType == "prescription"){
          title = "New reading"
          message = "There is a new prescription reading"
        }
        const notification = {
          title: title,
          options: {
            body: message
          },
          events:{
            onerror: function () {
            },
            onclick: function () {
            },
            onclose: function () {
            },
            onshow: function () {
            }
          }
        }
        this.$notification.requestPermission().then((permission) => {
          if(permission == 'granted' || permission == 'default'){
            this.$notification.show(notification.title, notification.options, notification.events);
          }
        })
      },
      showRemarks(readingItem){
        this.selectedRemarks = readingItem.remarks
        this.readingRemarksDialog = true
      },
      goToImage(photos){
        window.open(photos, '_blank').focus()
      },
      async markAsFinished(){
        var docRef = db.collection('prescriptions').doc(this.selectedPrescription.transactionId)
        docRef.update({
          'statusCode': 1,
          'remarks': this.prescriptionRemarks
        })
        .then(() => {
          this.readingDialog = false
          this.prescriptionRemarks = ''
          this.step = 1
        })
      },
      async getPrescriptionInformation(item){
        this.readingDialog = true
        this.selectedPrescription = item
        this.prescriptionAttachments = item.attachments
      },
      async saveSocialWorkerName(){
        const docRef = db.collection('portalData').doc('---portaldata---')
        await docRef.update('socialWorker', fv.arrayUnion(this.socialWorkerName));
      },
      async savePrescriptionDetails(storageURL){
        var prescription = {
          socialWorkerName: this.socialWorkerName,
          created: fv.serverTimestamp(),
          statusCode: 0,
          attachments: storageURL
        }
        db.collection('prescriptions').add(prescription)
          .then(() => {
            this.saveLoading = false
            this.prescriptionDialog = false
            this.socialWorkerName = ""
            this.fileNames = null
            this.snackbar = true
            this.snackbarText = "Prescription has been added.";
          })
      },
      async proceedPrescription(){
        this.saveLoading = true
        var customerExists = this.socialWorkerNames.includes(this.socialWorkerName);
        if(!customerExists){
          await this.saveSocialWorkerName();
        }
        var storageURL = []
        var counter = 0
        this.fileNames.forEach((file) => {
          if(file === "") return "";
          const name = new Date() + '-' + file.name
          const metadata = {
            contentType: file.type
          }
          const ref = firebaseApp.storage().ref()
          const task = ref.child('/prescriptions/' + name).put(file,metadata)
          task
            .then(snapshot => snapshot.ref.getDownloadURL())
            .then(url => {
              storageURL.push(url)
              counter++
              if(counter === this.fileNames.length){
                this.savePrescriptionDetails(storageURL)
              }
            })
        })
      },
      getDate(timestampedDate){
        if(timestampedDate === null){
          return '';
        }
        return moment(timestampedDate.toDate()).calendar();
      },
      getOrderDate(timestampedDate){
        if(timestampedDate === null){
          return '';
        }
        return moment(timestampedDate.toDate()).format('MMMM Do YYYY, h:mm:ss a');
      },
      getOrderStatus(statusCode){
        var result = '';
        switch(statusCode){
          case 0:
            result = 'PENDING';
            break;
          case 1:
            result = 'APPROVED';
            break;
          case -1:
            result = 'CANCELLED';
            break;
        }
        return result;
      },
      getPrescriptionStatus(statusCode){
        var result = '';
        switch(statusCode){
          case 0:
            result = 'For reading'
            break
          case 1:
            if(this.hasAccess){
              result = 'Finished'
            }
            else{
              result = 'Unread'
            }
            break
          case 2:
            result = 'Finished'
            break
        }
        return result;
      },
      showQuotationConfirm(model){
        this.selectedQuotation = model;
        this.confirmDialog = true;
        this.confirmHeader = 'Proceed to Orders?'
        this.confirmBody = 'Do you want to transfer this quotation to the orders?'
      },
      showCancelPrompt(item, isOrder){
        this.isOrder = isOrder;
        if(isOrder){
          this.selectedOrder = item;
        }
        else{
          this.selectedQuotation = item;
        }
        this.remarksDialog = true;
      },
      cancelTransaction(){
        if(this.isOrder){
          this.cancelOrder();
        }
        else{
          this.cancelQuotation();
        }
      },
      async cancelQuotation(){
        this.isLoading = true;
        const docRef = db.collection('quotations').doc(this.selectedQuotation.orderId);
        var batch = db.batch();
        const detailsRef = docRef.collection('details');
        detailsRef.get()
        .then((querySnapshot) => {
          batch.update(docRef, {
            statusCode: -1,
            remarks: this.remarks
          });
          querySnapshot.forEach((doc) => {
            var productRef = db.collection('products').doc(doc.data().productId);
            batch.update(productRef,{
              stock: fv.increment(doc.data().quantity)
            });
          })
          batch.commit().then(() => {
            this.selectedQuotation = [];
            this.isLoading = false;
            this.remarksDialog = false;
            this.remarks = '';
            this.snackbar = true;
            this.snackbarText = "Draft has been cancelled";
          })
        })
      },
      async cancelOrder(){
        this.isLoading = true;
        const docRef = db.collection('orders').doc(this.selectedOrder.orderId);
        var batch = db.batch();
        const detailRef = docRef.collection('details');
        detailRef.get().then((querySnapshot) => {
          batch.update(docRef, {
            orderStatusCode: -1,
            remarks: this.remarks
          });
          querySnapshot.forEach((doc) => {
            var productRef = db.collection('products').doc(doc.data().productId);
            var orderQuantity = parseInt(doc.data().quantity);
            batch.update(productRef, {
              stock: fv.increment(orderQuantity)
            })
          })
          batch.commit().then(() => {
            this.selectedOrder = [];
            this.remarks = '';
            this.isLoading = false;
            this.remarksDialog = false;
            this.snackbar = true;
            this.snackbarText = "Order has been cancelled";
          }).catch((err) => {
            console.log(err);
            this.isloading = false;
          })
          ;
        })
      },
      async deleteQuotationDetails(item){
        const docRef = db.collection('quotations').doc(this.selectedQuotation.orderId)
                        .collection('details').doc(item.itemId);
        const productRef = db.collection('products').doc(item.productId);
        var orderQuantity = parseInt(item.quantity);
        var batch = db.batch();
        batch.delete(docRef);
        batch.update(productRef, {
          stock: fv.increment(orderQuantity)
        });
        await batch.commit().then(() => {
          this.snackbar = true;
          this.snackbarText = "Item has been removed";
          const docRef = db.collection('quotations').doc(this.selectedQuotation.orderId).collection('details');
          docRef.get().then((querySnapshot) =>{
            this.isLoading = false;
            this.quotationItems = [];
            querySnapshot.forEach((doc) => {
              const data = {
                itemId: doc.id,
                orderId: doc.data().orderId,
                productId: doc.data().productId,
                sku: doc.data().sku,
                code: doc.data().code,
                brandName: doc.data().code,
                genericName: doc.data().genericName,
                category: doc.data().category,
                unit: doc.data().unit,
                price: doc.data().price,
                quantity: doc.data().quantity,
                amount: doc.data().amount
              };
              this.quotationItems.push(data);
            });
            this.quoteDetailsDialog = true; 
          })
        })
      },
      async editOrder(item){
        this.quoteDetailsDialog = false;
        this.selectedQuotation = item;
        this.isLoading = true;
        this.isDraft = true;
        const docRef = db.collection('quotations').doc(item.orderId).collection('details');
        await docRef.get().then((querySnapshot) =>{
          this.isLoading = false;
          this.quotationItems = [];
          querySnapshot.forEach((doc) => {
            const data = {
              itemId: doc.id,
              orderId: doc.data().orderId,
              productId: doc.data().productId,
              sku: doc.data().sku,
              code: doc.data().code,
              brandName: doc.data().code,
              genericName: doc.data().genericName,
              category: doc.data().category,
              unit: doc.data().unit,
              price: doc.data().price,
              quantity: doc.data().quantity,
              amount: doc.data().amount
            };
            this.quotationItems.push(data);
          });
          this.quoteDetailsDialog = true; 
        })
      },
      async getOrderInformation(item){
        this.isLoading = true;
        this.isOrder = true;
        this.isDraft = false;
        const docRef = db.collection('orders').doc(item.orderId).collection('details');
        await docRef.get().then((querySnapshot) => {
          this.isLoading = false;
          this.orderDetails = [];
          querySnapshot.forEach((doc) => {
            const data = {
              orderId: doc.data().orderId,
              sku: doc.data().sku,
              code: doc.data().code,
              brandName: doc.data().code,
              genericName: doc.data().genericName,
              category: doc.data().category,
              unit: doc.data().unit,
              price: doc.data().price,
              quantity: doc.data().quantity,
              amount: doc.data().amount
            };
            this.orderDetails.push(data);
          });
          this.selectedOrder = item;
          this.detailsDialog = true; 
        })
      },
      async getDraftInformation(item){
        this.isLoading = true;
        this.isDraft = true;
        this.isOrder = false;
        const docRef = db.collection('quotations').doc(item.orderId).collection('details');
        await docRef.get().then((querySnapshot) =>{
          this.isLoading = false;
          this.orderDetails = [];
          querySnapshot.forEach((doc) => {
            const data = {
              itemId: doc.id,
              orderId: doc.data().orderId,
              productId: doc.data().productId,
              sku: doc.data().sku,
              code: doc.data().code,
              brandName: doc.data().code,
              genericName: doc.data().genericName,
              category: doc.data().category,
              unit: doc.data().unit,
              price: doc.data().price,
              quantity: doc.data().quantity,
              amount: doc.data().amount
            };
            this.orderDetails.push(data);
          });
          this.selectedQuotation = item;
          this.detailsDialog = true; 
        })
      },
      async updateStatus(item, code){
        if(confirm('Approve this order?')){
          const docRef = db.collection('orders').doc(item.orderId);

          await docRef.update('orderStatusCode', code).then(() => {
            if(code == 1){
              this.snackbarText = "Order has been approved";
            }
            if(code == -1){
              this.snackbarText = "Order has been cancelled";
            }
            this.snackbar = true;
          });
        }
      },
      async proceedQuotation(){
        this.confirmDialog = false;
        this.isLoading = true;
        const item = this.selectedQuotation;
        const quotationRef = db.collection('quotations').doc(item.orderId);
        const orderRef = db.collection('orders').doc();
        var batch = db.batch();
        const orderData = {
          transactionCode: item.transactionCode.replace('DRAFT-','-'),
          socialWorkerName: item.socialWorkerName,
          customerName: item.customerName,
          chiefHead: item.chiefHead,
          totalAmount: parseFloat(item.totalAmount).toFixed(2),
          created: item.created,
          orderStatusCode: 0
        }
        batch.set(orderRef, orderData);
        batch.update(quotationRef, "statusCode", 1);
        await batch.commit().then(() => {
           this.proceedQuotationDetails(orderRef.id);
        })
      },
      async proceedQuotationDetails(docId){
        const colRef = db.collection('quotations').doc(this.selectedQuotation.orderId).collection('details');
        var batch = db.batch();
        colRef.get().then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const docRef = db.collection('orders').doc(docId).collection('details').doc();
            const data = {  
              orderId: docId,
              productId: doc.data().productId,
              sku: doc.data().sku,
              code: doc.data().code,
              brandName: doc.data().brandName,
              genericName: doc.data().genericName,
              category: doc.data().category,
              unit: doc.data().unit,
              price: doc.data().price,
              quantity: doc.data().quantity,
              amount: parseFloat(doc.data().amount).toFixed(2)
            }
            batch.set(docRef, data);
          })
          batch.commit().then(() => {
            this.isLoading = false;
            this.snackbar = true;
            this.snackbarText = "Draft has been moved to order";
          })
        })
       
      },
      getCurrency(amount){
        var formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'PHP'
        });
        return formatter.format(amount);
      },
      getRowTotal(model){
        var total = model.quantity * model.price
        return total;
      },
      itemRowBackground(item){
        var result = '';
        switch(item.orderStatusCode){
          case 0:
            result = 'orange lighten-4';
            break;
          case 1:
            result = 'APPROVED';
            break;
          case -1:
            result = 'grey';
            break;
        }
        return result;
      },
      prescriptionRowBackground(item){
        var result = '';
        switch(item.statusCode){
          case 0:
            result = 'orange lighten-4';
            break;
        }
        return result;
      },
      async printDetails(){
        const docRef = db.collection('orders').doc(this.selectedOrder.orderId).collection('details');
        this.isLoading = true;
        await docRef.get().then((querySnapshot) => {
          this.printItems = [];
          querySnapshot.forEach((doc) => {
            const data = {
              productName: doc.data().genericName,
              quantity: doc.data().quantity,
              unit: doc.data().unit,
              price: this.getCurrency(doc.data().price),
              total: (doc.data().price * doc.data().quantity).toFixed(2)
            };
            this.printItems.push(data);
          });
          this.selectedTransactionCode = this.selectedOrder.transactionCode;
          this.selectedCustomerName = this.selectedOrder.customerName;
          this.selectedOrderDate = this.getOrderDate(this.selectedOrder.created);
          this.selectedSocialWorkerName = this.selectedOrder.socialWorkerName;
          this.selectedChiefHead = this.selectedOrder.chiefHead;
          this.isLoading = false;
          this.print();
        })
      },
      async printDraftDetails(){
        const docRef = db.collection('quotations').doc(this.selectedQuotation.orderId).collection('details');
        this.isLoading = true;
        await docRef.get().then((querySnapshot) => {
          this.printItems = [];
          querySnapshot.forEach((doc) => {
            const data = {
              productName: doc.data().genericName,
              quantity: doc.data().quantity,
              unit: doc.data().unit,
              price: this.getCurrency(doc.data().price),
              total: (doc.data().price * doc.data().quantity).toFixed(2)
            };
            this.printItems.push(data);
          });
          this.selectedTransactionCode = this.selectedQuotation.transactionCode;
          this.selectedCustomerName = this.selectedQuotation.customerName;
          this.selectedOrderDate = this.getOrderDate(this.selectedQuotation.created);
          this.selectedSocialWorkerName = this.selectedQuotation.socialWorkerName;
          this.selectedChiefHead = this.selectedQuotation.chiefHead;
          this.isLoading = false;
          this.print();
        })
      },
      async printOrder(item){
        const docRef = db.collection('orders').doc(item.orderId).collection('details');
        this.isLoading = true;
        this.selectedTransactionCode = item.transactionCode;
        this.selectedCustomerName = item.customerName;
        this.selectedOrderDate = this.getOrderDate(item.created);
        this.selectedSocialWorkerName = item.socialWorkerName;
        this.selectedChiefHead = item.chiefHead;
        await docRef.get().then((querySnapshot) => {
          this.printItems = [];
          querySnapshot.forEach((doc) => {
            const data = {
              productName: doc.data().genericName,
              quantity: doc.data().quantity,
              unit: doc.data().unit,
              price: this.getCurrency(doc.data().price),
              total: (doc.data().price * doc.data().quantity).toFixed(2)
            };
            this.printItems.push(data);
          });
          this.isLoading = false;
          this.print();
        })
      },
      print(){
         setTimeout(() => {
            printJS({
              printable: 'printForm',
              type: 'html',
              scanStyles: false,
              targetStyle: ['*'],
              headerStyle: 'font-size: 24px; font-weight: 700;',
              header: 'Order details'
            })
          }, 450);
      },
      async updateDetailQuantity(item){
        const detailID = item.itemId;
        const productID = item.productId;
        const draftRef =  db.collection('quotations')
                            .doc(this.selectedQuotation.orderId)
        const detailRef = draftRef.collection('details')
                          .doc(detailID);
        const productRef = db.collection('products').doc(productID);
        var batch = db.batch();
        var increment = parseInt(item.quantity) - parseInt(this.editQuantity);
        var newAmount = parseFloat(item.price) * parseInt(this.editQuantity);
        var incrementAmount = (increment * -1) * parseFloat(item.price);
        batch.update(productRef, {
          stock: fv.increment(increment)
        })
        batch.update(draftRef, {
          totalAmount: fv.increment(incrementAmount)
        })
        batch.update(detailRef, {
          quantity: this.editQuantity,
          amount: newAmount
        });
        await batch.commit().then(() => {
          this.snackbar = true;
          this.snackbarText = "Draft quantity has been updated";
          this.quoteDetailsDialog = false;
        }).catch((err) => {
          console.log(err);
        })
      }
    },
    data: () => ({
      tab: null,
      step: 1,
      socialWorkerName: '',
      quotationPendingCount: 0,
      orderPendingCount: 0,
      prescriptionCount: 0,
      editQuantity: 0,
      isLoading: false,
      saveLoading: false,
      quoteDetailsDialog: false,
      remarksDialog: false,
      readingRemarksDialog: false,
      confirmDialog: false,
      confirmHeader: '',
      confirmBody: '',
      detailsDialog: false,
      prescriptionDialog: false,
      readingDialog: false,
      remarks: '',
      prescriptionRemarks: '',
      snackbar: false,
      snackbarText: '',
      search: '',
      searchQuotation: '',
      isOrder: false,
      isDraft: false,
      selectedPrescription: [],
      selectedQuotation: [],
      selectedOrder: [],
      selectedTransactionCode: '',
      selectedCustomerName: '',
      selectedOrderDate: null,
      selectedSocialWorkerName: '',
      selectedChiefHead: '',
      selectedRemarks: '',
      quotationItemHeader: [
        {text: "Generic name", value:"genericName"},
        {text: "Category", value:"category"},
        {text: "Price", value:"price"},
        {text: "Qty", value:"quantity"},
        {text: "Amount", value:"amount"},
        {text: "Unit", value:"unit"},
        {text: "Actions", value:"actions",searchable: false, sortable: false}
      ],
      quotationItems: [],
      headers: [
        {text: "", value: "detailAction", width: 50},
        {text: "Code", value: "transactionCode"},
        {text: "Client/Patient name", value: "customerName"},
        {text: "Total Amount", value: "totalAmount",searchable: false, sortable: false},
        {text: "Transaction Date", value: "created", searchable: false},
        {text: "Status", value:"orderStatusCode",searchable: false, sortable: false},
        {text: "Actions", value:"actions",searchable: false, sortable: false}
      ],
      quotationHeader: [
        {text: "", value: "detailAction", width: 50},
        {text: "Code", value: "transactionCode"},
        {text: "Client/Patient name", value: "customerName"},
        {text: "Total Amount", value: "totalAmount",searchable: false, sortable: false},
        {text: "Transaction Date", value: "created", searchable: false},
        {text: "Status", value:"orderStatusCode",searchable: false, sortable: false},
        {text: "Actions", value:"actions",searchable: false, sortable: false}
      ],
      prescriptionHeader: [
        {text: "", value: "detailAction", width: 50, searchable: false},
        {text: "Social Worker", value:"socialWorkerName"},
        {text: "Created at", value:"created"},
        {text: "Remarks", value:"remarks"},
        {text: "Status", value:"statusCode",searchable: false, sortable: false}
      ],
      orders: [],
      quotations: [],
      prescriptions: [],
      orderDetailsHeader: [
        {text: "Generic name", value:"genericName"},
        {text: "Category", value:"category"},
        {text: "Price", value:"price"},
        {text: "Qty", value:"quantity"},
        {text: "Amount", value:"amount"},
        {text: "Unit", value:"unit"},
      ],
      orderDetails: [],
      printHeaders: [
        {text: "Product Name", value: "productName"},
        {text: "Unit", value: "unit"},
        {text: "Qty", value: "quantity"},
        {text: "Price", value: "price"},
        {text: "Total", value: "total"},
      ],
      printItems: [],
      socialWorkerNames: [],
      fileNames: [],
      prescriptionAttachments: []
    })
}
</script>
<style>
</style>