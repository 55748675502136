<template>
    <div class="forgot-container">
        <div class="forgot-form">
            <div class="form-container">
                <form action="#">
                    <svg viewBox="0 0 128 128" class="logo">
                        <g transform="translate(-1074.394 -1142.922)">
                            <path id="Path_7" class="st0" d="M1157.66,1195.83l-0.03,22.23l-19.26,11.09l-19.24-11.13l0.03-22.23l19.26-11.09L1157.66,1195.83z
                                "></path>
                            <path id="Path_8" class="st0" d="M1190.95,1176.66l-0.02,20.62l-18.12,13.26l0.03-23.45l-24.41-14.13l-17.62-22.28l7.66-4.41
                                L1190.95,1176.66z"></path>
                            <path id="Path_9" class="st0" d="M1190.93,1197.28l-0.04,40.03l-19.28,11.1l-9.04-15.69l10.22-5.89l0.02-16.29L1190.93,1197.28z"></path>
                            <path id="Path_10" class="st0" d="M1149.44,1240.27l9.04,15.69l-16.1,9.27l-15.35-25.13l11.32,6.55L1149.44,1240.27z"></path>
                            <path id="Path_11" class="st0" d="M1130.81,1150.69l17.62,22.28l-9.98-5.78l-34.44,19.83l-0.03,22.01l0,0l-18.12,12.95l0.05-45.44
                                L1130.81,1150.69z"></path>
                            <path id="Path_12" class="st0" d="M1127.03,1240.11l15.35,25.13l-4.06,2.33l-52.49-30.38l0.02-15.21l18.12-12.95l0,0l-0.02,17.72
                                L1127.03,1240.11L1127.03,1240.11z"></path>
                        </g>
                    </svg>
                    <h1 class="orange--text text--darken-4">Forgot password?</h1>
                    <span>Reset your password by entering your email below</span>
                    <input type="email" placeholder="Email address" v-model="email">
                    <v-fab-transition>
                        <button @click.prevent="resetPassword">Send reset link</button>
                    </v-fab-transition>
                </form>
            </div>
        </div>
    </div>
</template>

<script>
import firebase from "../firebase/firebaseInit";
import "firebase/auth";
export default {
    data: () => ({
        email: "",
    }),
    methods: {
        async resetPassword(){
            await firebase.auth().sendPasswordResetEmail(this.email).then(() => {
                this.$router.push({name: "Login"});
            })
        }
    },

}
</script>

<style scoped>
    .forgot-container{
        height: 100%;
        display: flex;
        justify-content: center;
        background-color: #f55c47;
    }
    .logo{
        fill: #f55c47;
        width: 120px;
        height: 120px;
        margin: 10px 0;
    }
    h1{
        font-weight: bold;
        font-family: 'Montserrat', sans-serif;
        margin: 0;
    }
    span{
        font-size: 14px;
        letter-spacing: 0.5px;
    }
    .forgot-form{
        background-color: white;
        border-radius: 10px;
        box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,.22);
        position: relative;
        overflow: hidden;
        max-width: 768px;
        width: 100%;
        min-height: 480px;
        margin: auto;
    }
    form{
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 15px 50px;
        height: 100%;
        text-align: center
    }
    input{
        background-color: #eee;
        border: none;
        padding: 12px 15px;
        margin: 8px 0;
        width: 100%;
        transition: transform 80ms ease-in;
    }
    input:focus{
        outline: none;
        transform: scale(1.05);
    }
    button{
        border-radius: 30px;
        border: 1px solid #fc5404;
        background-color: #fc5404;
        color: #fff;
        font-weight: bold;
        font-size: 14px;
        letter-spacing: 1px;
        text-transform: uppercase;
        padding: 12px 45px;
        width: 100%;
        transition: transform 80ms ease-in;
        margin: 1.1rem 0;
    }
    button:hover{
        transform: scale(0.95)
    }
    button:focus{
        outline: none;
    }
</style>