import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../views/Login.vue";
import ForgotPassword from "../views/ForgotPassword.vue";
import Product from "../views/Product.vue";
import Ordering from "../views/Ordering.vue";
import Dashboard from "../views/Dashboard.vue";
import Users from "../views/Users.vue";
import store from '../store/index.js';
import "firebase/auth";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Login",
    component: Login,
    beforeEnter: (to,from,next) => {
      if(store.getters.isLoggedIn){
        next({
          name: 'Dashboard'
        })
      }
      else{
        sessionStorage.clear();
        next();
      }
    },
  },
  {
    path: "/forgot",
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    beforeEnter: (to, from, next) => {
      if(!store.getters.isLoggedIn){
        next({
          path: '/'
        });
      }
      else{
        next();
      }
    }
  },
  {
    path: "/products",
    name: "Products",
    component: Product,
    beforeEnter: (to, from, next) => {
      if(!store.getters.isLoggedIn){
        next({
          path: '/'
        });
      }
      else{
        if(!store.state.isAdmin){
          next({
            path: '/dashboard'
          });
        }
        else{
          next();
        }
      }
    }
  },
  {
    path: "/order",
    name: "Order",
    component: Ordering,
    beforeEnter: (to, from, next) => {
      if(!store.getters.isLoggedIn){
        next({
          path: '/'
        });
      }
      else{
        next();
      }
    }
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
    beforeEnter: (to, from, next) => {
      if(!store.getters.isLoggedIn){
        next({
          path: '/'
        });
      }
      else{
        if(!store.state.isAdmin){
          next({
            path: '/dashboard'
          });
        }
        else{
          next();
        }
      }
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
