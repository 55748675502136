import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Vue2Editor from "vue2-editor";
import VueFbCustomerChat from "vue-fb-customer-chat";
import VueNativeNotification from "vue-native-notification";
import vuetify from './plugins/vuetify';
import firebase from "./firebase/firebaseInit";
import "firebase/auth";
Vue.use(Vue2Editor);
Vue.use(VueFbCustomerChat, {
  page_id: 102340242236845,
  theme_color: '#e65100',
  locale: 'en_US'
})
Vue.use(VueNativeNotification, {
  requestOnNotify: true
})
Vue.config.productionTip = false;

let app;
firebase.auth().onAuthStateChanged((user) => {
  if(user != null){
    store.dispatch('getCurrentUser');
    let db = firebase.firestore();
    var ref = db.collection('users').where('userId','==', user.uid);
    ref.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        store.isAdmin = doc.data().roleCode == 0
      })
    });
  }
  if(!app){
    new Vue({
      router,
      store,
      vuetify,
      render: (h) => h(App)
    }).$mount("#app");
  }
});

