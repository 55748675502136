<template>
  <v-app>
    <v-card flat color="orange darken-4">
      <v-card-title>
        <h2 class="py-2 mx-3 text-h4 font-weight-black white--text">Order details</h2>
        <v-spacer></v-spacer>
        <v-btn
          text
          large
          dark
          @click="proceedDraft"
          :disabled="!isTransactionDone"
          >
          <v-icon left>mdi-printer</v-icon>
          <span>Create draft</span>
        </v-btn>
        <v-btn
          class="mx-3"
          large
          color="orange lighten-1"
          @click="proceedOrder"
          :disabled="!isTransactionDone"
          >
          <v-icon left>mdi-content-save</v-icon>
          <span>Proceed</span>
        </v-btn>
      </v-card-title>
      <v-form 
        ref="form"
        lazy-validation
        class="px-4">
        <v-container fluid>
          <v-row no-gutters>
            <v-col cols="12">
              <v-combobox
                v-model="customerName"
                :items="customerNames"
                auto-select-first
                solo
                flat
                clearable
                color="orange darken-4"
                prepend-inner-icon="mdi-account-box"
                label="Client/Patient name">
              </v-combobox>
            </v-col>
            <v-col cols="12">
              <v-combobox
                v-model="socialWorkerName"
                :items="socialWorkers"
                auto-select-first
                solo
                flat
                clearable
                color="orange darken-4"
                prepend-inner-icon="mdi-account"
                label="Social worker name">
              </v-combobox>
            </v-col>
            <v-col cols="12">
              <v-combobox
                v-model="chiefHead"
                :items="chiefHeads"
                auto-select-first
                solo
                flat
                clearable
                color="orange darken-4"
                prepend-inner-icon="mdi-account"
                label="CIU Chief/Head">
              </v-combobox>
              
            </v-col>
          </v-row>
        </v-container>
      </v-form>
      <v-container fluid>
      </v-container>
      <v-toolbar
        color="orange darken-4"
        flat
        height="20px">
      </v-toolbar>
    </v-card>
    <v-card 
      style="margin-top: -50px"
      class="mx-7"
      >
        <v-toolbar flat>
          <v-toolbar-title>
            <span class="text-h5 orange--text text--darken-3">Product details</span>
            <v-divider vertical class="mx-2"></v-divider>
            <span class="text-subtitle  grey--text text--lighten-1">(Click quantity column to edit quantity)</span>
          </v-toolbar-title>

          <v-spacer></v-spacer>
          <v-btn @click="addProductToOrder" x-large icon color="orange darken-4">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider></v-divider>
        <v-card-text>
          <v-data-table
            :headers="orderedProductHeaders"
            :items="orderedProducts">
            <template v-slot:item.price="{item}">
              <span style="margin-right: 0.2em">PHP</span>
              <span>{{item.price}}</span>
            </template>
            <template v-slot:item.amount="{item}">
              <span style="margin-right: 0.2em">PHP</span>
              <span>{{item.amount}}</span>
            </template>
            <template v-slot:item.quantity="{item}">
              <v-edit-dialog
                large
                persistent
                @open="editQuantity = item.quantity"
                @save="updateAmount(item,item.price)"
                >
                {{item.quantity}}
                <template v-slot:input>
                  <div class="mt-4 text-h6">
                    Update quantity
                  </div>
                   <v-text-field
                    outlined
                    v-model="editQuantity"
                    label="Enter quantity"
                    single-line
                    autofocus
                    counter
                    type="number"
                    color="orange darken-3">
                  </v-text-field>
                </template>
              </v-edit-dialog>
            </template>
            <template v-slot:item.actions="{item}">
              <v-icon medium 
                @click="deleteProduct(item)" 
                class="orange--text text--darken-4">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-container>
            <v-row>
              <v-col>
                <span style="float:right; margin-left:0.3em;" class="text-h5 orange--text text--darken-4">{{ getCurrency(getTotalAmount)}}</span>
                <span style="float:right;" class="text-h5">Total amount:</span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-dialog v-model="addProductDialog" max-width="1100px" transition="dialog-bottom-transition">
          <v-sheet>
            <v-app-bar
              dark
              color="orange darken-4">
              <v-btn icon dark @click="addProductDialog=false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Search product</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="proceedAddProduct">
                    Proceed
                </v-btn>
              </v-toolbar-items>
            </v-app-bar>
            <v-container fluid>
              <v-row>
                <v-col>
                  <v-data-table
                    v-model="selectedProducts"
                    :headers="productHeaders"
                    :items="products"
                    :search="productSearch"
                    :single-select="singleSelect"
                    item-key="productId"
                    show-select
                    >
                    <template v-slot:top>
                      <v-toolbar flat>
                        <v-text-field 
                          solo 
                          v-model="productSearch" 
                          label="Search" 
                          single-line 
                          clearable
                          clear-icon="mdi-close-circle"
                          color="orange darken-2"
                          prepend-inner-icon="mdi-magnify"
                          hide-details>
                        </v-text-field>
                      </v-toolbar>
                    </template>
                   
                  </v-data-table>
                </v-col>
              </v-row>
            </v-container>
          </v-sheet>
        </v-dialog>
    </v-card>
    <v-snackbar
      light
      v-model="isSaved">
      Order has been saved.
      <template v-slot:action="{ attrs }">
        <v-btn
          color="orange darken-4"
          text
          v-bind="attrs"
          @click="isSaved = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      light
      v-model="isDraftSaved">
      Draft has been saved.
      <template v-slot:action="{ attrs }">
        <v-btn
          color="orange darken-4"
          text
          v-bind="attrs"
          @click="isDraftSaved = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      light
      v-model="isError">
      {{errorText}}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="orange darken-4"
          text
          v-bind="attrs"
          @click="isError = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <div id="printForm" class="print-form">
      <v-divider></v-divider>
      <div style="display:inline-block;width:50%;">
        <h4>Reference number: {{printTransactionCode}}</h4>
      </div>
      <div style="display:inline-block;width:50%;">
        <h4>Order date: {{selectedOrderDate}}</h4>
      </div>
      <div>
        <h4>Customer name: {{printPatientName}}</h4>
      </div>
      <v-divider class="my-2"></v-divider>
      <div>
        <template>
          <v-simple-table>
            <template v-slot:default>
              <thead>
                <tr>
                  <th v-for="header in printHeaders"
                      :key="header.text"
                      class="text-left">
                      {{header.text}}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in printItems"
                  :key="item.productName">
                  <td>
                    {{item.productName}}
                  </td>
                  <td>
                    {{item.unit}}
                  </td>
                  <td>
                    {{item.quantity}}
                  </td>
                  <td>
                    {{item.price}}
                  </td>
                  <td>
                    {{item.total}}
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </template>
      </div>
      <div>
          <h4 class="my-4">Total Amount: {{getCurrency(getTotalPrintAmount)}}</h4>
      </div>
      <v-divider class="mb-5"></v-divider>
      <div style="display:inline-block;width:33%;">
        <p>Prepared by: </p>
        <h4>{{printSWName}}</h4>
        <h5 style="margin-top:-10px;">Social Worker Name</h5>
      </div>
      
      <div style="display:inline-block;width:33%;">
        <p>Approved by: </p>
        <h4>{{printChiefHead}}</h4>
        <h5 style="margin-top:-10px;">CIU Chief/Head</h5>
      </div>
      
      <div style="display:inline-block;width:33%;">
        <p>Received by: </p>
        <h4>{{printPatientName}}</h4>
        <h5 style="margin-top:-10px;">Client name</h5>
      </div>
    </div>
  </v-app>
</template>

<script>
import {firebaseApp,fv} from "../firebase/firebaseInit";
import printJS from "print-js";
import moment from "moment"
let db = firebaseApp.firestore();
export default {
  components: {

  },
  data: () => ({
    editQuantity: 1,
    isSaved: false,
    isDraftSaved: false,
    isError: false,
    isTransactionDone: true,
    errorText: '',
    addProductDialog: false,
    singleSelect: false,
    selectedOrderDate: (new Date()).toLocaleDateString("en-US"),
    productSearch: "",
    totalAmount: 0.0,
    socialWorkers: [],
    customerNames: [],
    chiefHeads: [],
    products: [],
    selectedProducts: [],
    orderedProducts: [],
    cachedProducts: [],
    printTransactionCode: '',
    printHeaders: [
      {text: "Product Name", value: "productName"},
      {text: "Unit", value: "unit"},
      {text: "Qty", value: "quantity"},
      {text: "Price", value: "price"},
      {text: "Total", value: "total"},
    ],
    printSWName: "",
    printChiefHead: "",
    printPatientName: "",
    printItems: [],
    productHeaders: [
      // {text: "Brand Name", value: "brandName"},
      {text: "Generic Name", value: "genericName"},
      {text: "Description", value: "description", filterable: false},
      {text: "Category", value: "category", filterable: false},
      {text: "Stock", value: "stock", filterable: false},
      {text: "Price", value: "price", sortable: false, filterable: false},
      {text: "Unit", value:"unit", sortable: false, filterable: false},
    ],
    orderedProductHeaders: [
      {text: "SKU", value: "sku"},
      {text: "Code", value: "code"},
      // {text: "Brand Name", value: "brandName"},
      {text: "Product Name", value: "genericName"},
      {text: "Category", value: "category"},
      {text: "Unit", value:"unit", sortable: false},
      {text: "Stock on-hand", value: "stock"},
      {text: "Price", value: "price", sortable: false},
      {text: "Qty", value: "quantity", sortable: false, width: "100px"},
      {text: "Amount", value: "amount", sortable: false},
      {text: "Actions", value: "actions", sortable: false},
    ],
    socialWorkerName: "",
    customerName: "",
    patientName: "",
    chiefHead: "",
  }),
  created() {
    db.collection('products').orderBy('stock','desc')
      .onSnapshot((querySnapshot) => {
        var items = [];
        querySnapshot.forEach((doc) => {
          const data = {
            productId: doc.data().productId,
            sku: doc.data().sku,
            code: doc.data().code,
            brandName: doc.data().brandName,
            genericName: doc.data().genericName,
            description: doc.data().description,
            category: doc.data().category,
            unit: doc.data().unit,
            stock: doc.data().stock,
            price: doc.data().price,
            expiry: doc.data().expiry
          };
          items.push(data);
        });
        this.products = items;
    });
    db.collection('portalData').doc('---portaldata---')
    .onSnapshot((docSnapshot) => {
      this.socialWorkers = docSnapshot.data().socialWorker
      this.chiefHeads = docSnapshot.data().chiefHead
      this.customerNames = docSnapshot.data().patientName
    })
  },
  computed: {
    getTotalAmount(){
      var total = 0.0;
      this.orderedProducts.forEach((item) => {
        total += parseFloat(item.amount);
      });
      return total;
    },
    getTotalPrintAmount(){
      var total = 0.0;
      this.printItems.forEach((item) => {
        total += parseFloat(item.total);
      });
      return total;
    },
    getDate(){ 
      return moment().format('MMMM Do YYYY, h:mm:ss a');
    },
    getTotal(quantity, price){
      return quantity * price;
    },
  },
  watch: {

  },
  methods:{
    getTransactionCode(){
      var result = [];
      var range = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      for(var i =  0; i < 6; i++)
      {
        var randomNumber = Math.floor(Math.random() * (range.length - 1));
        var randomChar = range.charAt(randomNumber)
        result.push(randomChar);
      }
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0');
      return "QPI-" + mm + dd + result.join('');
    },
    getDraftTransactionCode(){
      var result = [];
      var range = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
      for(var i =  0; i < 6; i++)
      {
        var randomNumber = Math.floor(Math.random() * (range.length - 1));
        var randomChar = range.charAt(randomNumber)
        result.push(randomChar);
      }
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      var mm = String(today.getMonth() + 1).padStart(2, '0');
      return "QPIDRAFT-" + mm + dd + result.join('');
    },
    async saveSocialWorkerName(){
      const docRef = db.collection('portalData').doc('---portaldata---')
      await docRef.update('socialWorker', fv.arrayUnion(this.socialWorkerName));
    },
    async savePatientName(){
      const docRef = db.collection('portalData').doc('---portaldata---')
      await docRef.update('patientName', fv.arrayUnion(this.chiefHead));
    },
    async saveChiefName(){
      const docRef = db.collection('portalData').doc('---portaldata---')
      await docRef.update('chiefHead', fv.arrayUnion(this.chiefHead));
    },
    async proceedDraft(){
      this.isTransactionDone = false;
      this.printSWName = this.socialWorkerName;
      this.printChiefHead = this.chiefHead;
      this.printPatientName = this.customerName;
      this.selectedOrderDate = (new Date()).toLocaleDateString("en-US");
      if(this.customerName == ''){
        this.isError = true;
        this.errorText = 'Please input a customer/patient name'
        return;
      }
      if(this.socialWorkerName == ''){
        this.isError = true;
        this.errorText = 'Please input a SW name'
        return;
      }
      if(this.chiefHead == ''){
        this.isError = true;
        this.errorText = 'Please input a Chief head'
        return;
      }
      if(this.orderedProducts.length == 0){
        this.isError = true;
        this.errorText = 'You did not select any product'
        return;
      }
      var exists = this.socialWorkers.includes(this.socialWorkerName);
      if(!exists && this.socialWorkerName != ""){
        await this.saveSocialWorkerName();
      }
      var customerExists = this.customerNames.includes(this.customerName);
      if(!customerExists && this.customerName != ""){
        await this.savePatientName();
      }
      var chiefExists = this.chiefHeads.includes(this.chiefHead);
      if(!chiefExists && this.chiefHead != ""){
        await this.saveChiefName();
      }
      const draftRef = await db.collection('quotations').doc();
      var generatedCode = this.getDraftTransactionCode()
      await draftRef.set({
        transactionCode: generatedCode,
        socialWorkerName: this.socialWorkerName,
        chiefHead: this.chiefHead,
        customerName: this.customerName,
        totalAmount: this.getTotalAmount,
        created: new Date(),
        statusCode: 0
      }).then(() => {
        this.addDraftDetails(draftRef.id, generatedCode);
      })
    },
    async addDraftDetails(docID, transCode){
      var batch = db.batch()
      const transactionID = docID
      const generatedCode = transCode
      this.printItems = [];
      this.orderedProducts.forEach((product) => {
        const data = {
          orderId: transactionID,
          productId: product.productId,
          sku: product.sku,
          code: product.code,
          brandName: product.brandName,
          genericName: product.genericName,
          category: product.category,
          unit: product.unit,
          price: product.price,
          quantity: parseInt(product.quantity),
          amount: product.amount
        };
        this.printItems.push({
          productName: product.genericName,
          unit: product.unit,
          quantity: product.quantity,
          price: product.price,
          total: (product.quantity * product.price).toFixed(2),
        });
        var docRef = db.collection('quotations').doc(docID).collection('details').doc();
        var productRef = db.collection('products').doc(product.productId);
        var decrement = (parseInt(product.quantity) * -1);
        batch.update(productRef, {
          stock: fv.increment(decrement)
        });
        batch.set(docRef, data);
      });
      await batch.commit().then(() => {
        this.totalAmount = this.getTotalAmount;
        this.isDraftSaved = true;
        this.print(generatedCode);
        this.clear();
        this.isTransactionDone = true;
      });
    },
    async proceedOrder(){
      this.isTransactionDone = false;
      this.printSWName = this.socialWorkerName;
      this.printChiefHead = this.chiefHead;
      this.printPatientName = this.customerName;
      this.selectedOrderDate = (new Date()).toLocaleDateString("en-US");
      if(this.customerName == ''){
        this.isError = true;
        this.errorText = 'Please input a customer/patient name'
        return;
      }
      if(this.socialWorkerName == ''){
        this.isError = true;
        this.errorText = 'Please input a SW name'
        return;
      }
      if(this.chiefHead == ''){
        this.isError = true;
        this.errorText = 'Please input a Chief head'
        return;
      }
      if(this.orderedProducts.length == 0){
        this.isError = true;
        this.errorText = 'You did not select any product'
        return;
      }
      var exists = this.socialWorkers.includes(this.socialWorkerName);
      if(!exists){
        await this.saveSocialWorkerName();
      }
      var customerExists = this.customerNames.includes(this.customerName);
      if(!customerExists){
        await this.savePatientName();
      }
      var chiefExists = this.chiefHeads.includes(this.chiefHead);
      if(!chiefExists){
        await this.saveChiefName();
      }
      var orderRef = await db.collection('orders').doc();
      var generatedCode = this.getTransactionCode();
      await orderRef.set({
        transactionCode: generatedCode,
        socialWorkerName: this.socialWorkerName,
        chiefHead: this.chiefHead,
        customerName: this.customerName,
        totalAmount: this.getTotalAmount,
        created: new Date(),
        orderStatusCode: 0
      }).then(() => {
        this.addOrderDetails(orderRef.id, generatedCode);
      })
    },
    deleteProduct(item){
      const index = this.orderedProducts.indexOf(item);
      // const selectedIndex = this.selectedProducts.find(s => s.productId === productID);
      // this.selectedProducts.splice(selectedIndex, 1);
      this.orderedProducts.splice(index, 1);
      
    },
    async addOrderDetails(docID, transCode){
      var batch = db.batch();
      this.printItems = [];
      const transactionID = docID;
      const generatedCode = transCode;
      this.orderedProducts.forEach((product) => {
        const data = {
          orderId: transactionID,
          productId: product.productId,
          sku: product.sku,
          code: product.code,
          brandName: product.brandName,
          genericName: product.genericName,
          category: product.category,
          unit: product.unit,
          price: product.price,
          quantity: parseInt(product.quantity),
          amount: product.amount
        };
        this.printItems.push({
          productName: product.genericName,
          unit: product.unit,
          quantity: product.quantity,
          price: product.price,
          total: (product.quantity * product.price),
        });
        var docRef = db.collection('orders').doc(docID).collection('details').doc();
        var productRef = db.collection('products').doc(product.productId);
        var decrement = (parseInt(product.quantity) * -1);
        batch.update(productRef, {
          stock: fv.increment(decrement)
        });
        batch.set(docRef, data);
      });
      await batch.commit().then(() => {
        // Show saved status;
        this.totalAmount = this.getTotalAmount;
        this.isSaved = true;
        this.print(generatedCode);
        this.isTransactionDone = true;
      })
    },
    proceedAddProduct(){
      this.cachedProducts = this.orderedProducts;
      this.orderedProducts = [];
      this.selectedProducts.forEach((item) => {
        if(this.cachedProducts.length > 0 ){
          var quantity = 1;
          var amount = item.price;
          if(this.cachedProducts.find(c => c.sku == item.sku) != null){
            quantity = this.cachedProducts.find(c => c.sku == item.sku).quantity;
            amount = this.cachedProducts.find(c => c.sku == item.sku).amount;
          }
          this.orderedProducts.push({
            productId: item.productId,
            sku: item.sku,
            code: item.code,
            brandName: item.brandName,
            genericName: item.genericName,
            category: item.category,
            unit: item.unit,
            price: item.price,
            stock: item.stock,
            quantity: quantity,
            amount: amount,
          });
        }
        else{
          this.orderedProducts.push({
            productId: item.productId,
            sku: item.sku,
            code: item.code,
            brandName: item.brandName,
            genericName: item.genericName,
            category: item.category,
            unit: item.unit,
            price: item.price,
            stock: item.stock,
            quantity: 1,
            amount: item.price * 1
          });
        }
      }); 
      this.addProductDialog = false;
    },
    addProductToOrder(){
      this.addProductDialog = true;
    },
    updateAmount(model, price){
      if(parseInt(model.stock) < parseInt(this.editQuantity)){
        this.isError = true;
        this.errorText = 'Ordered quantity cannot be greater than stocks on hand.'
        return;
      }
      model.quantity = this.editQuantity;
      model.amount = (this.editQuantity * price).toFixed(2);
    },
    print(transactionCode){
      this.printTransactionCode = transactionCode;
      setTimeout(() => {
        printJS({
          printable: 'printForm',
          type: 'html',
          scanStyles: false,
          targetStyle: ['*'],
          header: 'Order details'
        })
      }, 450);
      this.clear();
    },
    getCurrency(amount){
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'PHP'
      });

      return formatter.format(amount);
    },
    clear(){
      this.socialWorkerName = "";
      this.customerName = "";
      this.patientName = "";
      this.chiefHead = "";
      this.productSearch = "";
      this.orderedProducts = [];
      this.selectedProducts = [];
    },
  }
}
</script>

<style>
  .print-form{
    z-index: -99;
  }
</style>