import Vue from 'vue'
import Vuex from 'vuex'
import firebase from "../firebase/firebaseInit";
import "firebase/auth";
import createPersistedState from 'vuex-persistedstate';
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: null,
    isAuthenticated: false,
    isAdmin: true,
  },
  mutations: {
    setProfileInfo(state, payload){
      state.isAuthenticated = true;
      state.user = payload;
      state.isAdmin = (parseInt(payload.roleCode) === 0);
    },
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage,
  })],
  getters: {
    isLoggedIn() {
      if(firebase.auth().currentUser == null){
        return false;
      }
      return true;
    },
  },
  actions: {
    async getCurrentUser({commit}) {
      var profileID = firebase.auth().currentUser.uid;
      let db = firebase.firestore();
      var ref = db.collection('users').where('userId','==',profileID);
      await ref.get().then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          commit("setProfileInfo", doc.data());
        })
      })
    }
  },
  modules: {
  }
})
