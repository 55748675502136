<template>
    <v-app>
        <v-card flat color="orange darken-4">
            <v-card-title>
                <h2 class="py-2 mx-3 text-h4 font-weight-black white--text">
                    Users
                </h2>
            </v-card-title>
            <v-toolbar color="orange darken-4" flat height="20px"></v-toolbar>
        </v-card>
        <v-card
            style="margin-top: -25px"
            class="mx-7">
            <v-toolbar flat>
                <span class="text-h6 orange--text text--darken-3">User List</span>
                <v-spacer></v-spacer>
                <v-btn text color="orange darken-4"
                    @click="addDialog = true">
                    <v-icon left>
                        mdi-plus
                    </v-icon>
                    <span>Add user</span>
                </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
                <v-data-table
                    :headers="headers"
                    :items="users"
                    >
                    <template v-slot:item.roleCode="{item}">
                        {{getRoleCode(item.roleCode)}}
                    </template>
                    <template v-slot:item.actions="{item}">
                        <v-icon medium @click="editUser(item)" class="orange--text text--darken-4 mr-2">mdi-pencil</v-icon>
                        <v-icon medium @click="deleteProduct(item)" class="orange--text text--darken-4">mdi-delete</v-icon>
                    </template>
                    <template v-slot:item.password="{item}">
                        <v-btn 
                            text 
                            color="orange darken-4"
                            small 
                            @click="editUserPassword(item)">
                            <v-icon left>mdi-pencil</v-icon>
                            <span>Reset password</span>
                        </v-btn>
                    </template>  
                </v-data-table>
            </v-card-text>
            
        </v-card>
        <v-dialog v-model="editDialog"
            transition="dialog-bottom-transition"
            max-width="600"
            scrollable>
            <v-sheet>
                <v-app-bar
                    dark
                    flat
                    color="orange darken-4">
                    <v-btn
                        icon
                        dark
                        @click="editDialog=false">   
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Edit user</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn dark text @click="createUserAuth">
                            <span>Update</span>
                        </v-btn>
                    </v-toolbar-items>
                </v-app-bar>
                <v-container fluid>
                    <v-form class="mt-2">
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field 
                                    color="orange darken-2" 
                                    v-model="selectedUser.lastName" 
                                    outlined
                                    label="Last name"
                                    prepend-inner-icon="mdi-account"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field 
                                    color="orange darken-2" 
                                    v-model="selectedUser.firstName" 
                                    outlined
                                    label="First name"></v-text-field>
                            </v-col><v-col cols="12">
                                <v-text-field 
                                    color="orange darken-2" 
                                    v-model="selectedUser.email" 
                                    readonly
                                    label="Email address"
                                    outlined
                                    prepend-inner-icon="mdi-email"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-select
                                    :items="roleItems"
                                    v-model="selectedUser.roleCode"
                                    item-text="text"
                                    item-value="value"
                                    prepend-inner-icon="mdi-account"
                                    label="Role"
                                    outlined>
                                </v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-sheet>
        </v-dialog>
        <v-dialog
            v-model="addDialog"
            transition="dialog-bottom-transition"
            max-width="600"
            scrollable>
            <v-sheet>
                <v-app-bar
                    dark
                    flat
                    color="orange darken-4">
                    <v-btn
                        icon
                        dark
                        @click="addDialog=false">   
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Add user</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-btn dark text @click="createUserAuth">
                            <span>Save</span>
                        </v-btn>
                    </v-toolbar-items>
                </v-app-bar>
                <v-container fluid>
                    <v-form class="mt-2">
                        <v-row>
                            <v-col cols="12" md="6">
                                <v-text-field 
                                    color="orange darken-2" 
                                    v-model="lastName" 
                                    outlined
                                    label="Last name"
                                    prepend-inner-icon="mdi-account"></v-text-field>
                            </v-col>
                            <v-col cols="12" md="6">
                                <v-text-field 
                                    color="orange darken-2" 
                                    v-model="firstName" 
                                    outlined
                                    label="First name"></v-text-field>
                            </v-col><v-col cols="12">
                                <v-text-field 
                                    color="orange darken-2" 
                                    v-model="email" 
                                    label="Email address"
                                    outlined
                                    prepend-inner-icon="mdi-email"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field 
                                    color="orange darken-2" 
                                    v-model="password"
                                    type="password" 
                                    label="Password"
                                    outlined
                                    prepend-inner-icon="mdi-key"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-select
                                    :items="roleItems"
                                    v-model="roleCode"
                                    item-text="text"
                                    item-value="value"
                                    prepend-inner-icon="mdi-account"
                                    label="Role"
                                    outlined>

                                </v-select>
                            </v-col>
                        </v-row>
                    </v-form>
                </v-container>
            </v-sheet>
        </v-dialog>
        <v-snackbar v-model="statusSnackbar"
                    timeout="2500"
                    top right>
            {{snackbarText}}
        </v-snackbar>
    </v-app>
</template>

<script>
import firebase from '../firebase/firebaseInit'
import 'firebase/auth'
let db = firebase.firestore()
export default {
    data: () => ({
        email: '',
        password: '',
        lastName: '',
        firstName: '',
        roleCode: '',
        addDialog: false,
        editDialog: false,
        isLoading: false,
        statusSnackbar: false,
        snackbarText: '',
        selectedUser: {
            roleCode: 1,
            email: '',
            lastName: '',
            firstName: ''
        },
        roleItems: [
            {text: "Administrator", value: "0"},
            {text: "Social Worker", value: "1"},
        ],
        headers: [
            {text: "Last name", value: "lastName"},
            {text: "First name", value: "firstName"},
            {text: "Email address", value: "email"},
            {text: "Password", value: "password"},
            {text: "Role", value: "roleCode"},
            {text: "Actions", value: "actions"},
        ],
        users: [],
    }),
    computed: {
       
    },
    created() {
        db.collection('users')
            .onSnapshot((querySnapshot) => {
                var items = [];
                querySnapshot.forEach((doc) => {
                    const data = {
                        userId: doc.id,
                        email: doc.data().email,
                        lastName: doc.data().lastName,
                        firstName: doc.data().firstName,
                        roleCode: doc.data().roleCode
                    }
                    items.push(data);
                })
                this.users = items;
            });
    },
    methods: { 
        async editUserPassword(user){
            var email = user.email;
            firebase.auth().sendPasswordResetEmail(email)
                .then(() => {
                    this.statusSnackbar = true;
                    this.snackbarText = 'Password reset link has been sent.'
                });
        },
        async removeUser(item){
            const docref = db.collection('users').doc(item.userId);
            await docref.delete().then(() => {
                this.statusSnackbar = true;
                this.snackbarText = 'User record has been removed.';
            })
        },
        editUser(item){
            this.selectedUser = item;
            console.log(item);
            this.editDialog = true;

        },
        proceedEdit(){
            const docRef = db.collection('users').doc(this.selectedUser.userId);
            docRef.update(this.selectedUser).then(() => {
                this.editDialog = false;
                this.statusSnackbar = true;
                this.snackbarText = 'User has been updated'
            })
        },
        getRoleCode(roleCode){
            if(roleCode == 0){
                return 'Administrator';
            }
            return 'Social Worker';
        },
        async createUserAuth(){
            firebase
                .auth()
                .createUserWithEmailAndPassword(this.email, this.password)
                .then((authCredential) => {
                    var id = authCredential.user.uid;
                    this.createUser(id);
                });
        },
        clear() {
            this.email = "";
            this.password = "";
            this.firstName = "";
            this.lastName = "";
            this.roleCode = "";
        },
        async createUser(userID) {
            const docRef = await db.collection('users').doc();
            await docRef.set({
                userId: userID,
                email: this.email,
                lastName: this.lastName,
                firstName: this.firstName,
                roleCode: this.roleCode,
            }).then(() => {
                this.addDialog = false;
                this.snackbarText = "User has been created";
                this.statusSnackbar = true;
            }).catch((err) => {
                this.snackbarText = err.message;
                this.statusSnackbar = true;
            })
            
        }
    }
}
</script>

<style>

</style>
