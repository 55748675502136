<template>
   <v-app>
      <v-container fluid>
        <v-row>
          <v-col>
            <v-text-field 
              solo 
              v-model="search" 
              label="Search" 
              single-line 
              clearable
              clear-icon="mdi-close-circle"
              color="orange darken-2"
              prepend-inner-icon="mdi-magnify"
              hide-details>
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
             <v-data-table 
                :headers="headers" 
                :items="products"
                :items-per-page="15"
                :search="search"
                class="elevation-1"
                >
                  <template v-slot:top>
                    <v-toolbar flat>
                      <v-toolbar-title class="orange--text text--darken-2 text-h5">Products</v-toolbar-title>
                      <v-spacer></v-spacer>
                      <v-fab-transition>
                        <v-btn
                          color="orange darken-2"
                          dark
                          v-if="hasAccess"
                          class="mb-2"
                          @click="dialog = true"
                          >
                          <v-icon left>mdi-plus</v-icon>
                          <span>New product</span>
                        </v-btn>
                      </v-fab-transition>
                       <!-- Dialog for add product -->
                      <v-dialog
                        v-model="dialog"
                        transition="dialog-bottom-transition"
                        max-width="720"
                        scrollable>
                        <v-sheet>
                          <v-app-bar
                            dark
                            color="orange darken-4">
                            <v-btn
                              icon
                              dark
                              @click="dialog=false">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>Add Product</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                              <v-btn
                                dark
                                text
                                @click="saveProduct">
                                Save
                              </v-btn>
                            </v-toolbar-items>
                          </v-app-bar>
                          <v-container fluid>
                            <v-form
                                lazy-validation
                                ref="form">
                                <v-row>
                                  <v-col cols="12">
                                    <v-text-field color="orange darken-2" :rules="[rules.required]" v-model="sku" label="SKU"></v-text-field>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-text-field color="orange darken-2" :rules="[rules.required]" v-model="code" label="Product code" ></v-text-field>
                                  </v-col>
                                  <!-- <v-col cols="12" md="6">
                                    <v-text-field color="orange darken-2" clearable v-model="brandName" :rules="[rules.required]" label="Brand name"></v-text-field>
                                  </v-col> -->
                                  <v-col cols="12" md="6">
                                    <v-text-field color="orange darken-2" clearable v-model="genericName" :rules="[rules.required]" label="Generic name"></v-text-field>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-textarea 
                                      v-model="description"
                                      color="orange darken-2"
                                      auto-grow
                                      counter="120"
                                      label="Description"></v-textarea>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <v-autocomplete
                                      v-model="category"
                                      :items="categories"
                                      auto-select-first
                                      item-text="name"
                                      single-line
                                      color="orange darken-2"
                                      placeholder="Category"
                                      >

                                    </v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <v-text-field color="orange darken-2" clearable counter="10" v-model="unit" :rules="[rules.required]" label="Unit"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <v-text-field color="orange darken-2" :rules="[rules.required]" v-model="stock" type="number" label="No. of stocks"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <v-text-field color="orange darken-2" prefix="₱" :rules="[rules.required]" v-model="price" type="currency" label="Price"></v-text-field>
                                  </v-col>
                                  <!-- <v-col cols="12">
                                    <v-text-field color="orange darken-2"  v-model="expiry" label="Expiration date" type="date"></v-text-field>
                                  </v-col> -->
                                </v-row>
                            </v-form>
                            
                          </v-container>
                        </v-sheet>
                      </v-dialog>
                      <!-- Dialog for delete product -->
                      <v-dialog 
                        v-model="dialogDelete" 
                        transition="dialog-bottom-transition"
                        max-width="500px">
                          <v-card>
                            <v-card-title class="text-h6 orange--text text--darken-4">Delete product?</v-card-title>
                            <v-card-text>
                              <p>
                                You are trying to delete <span class="font-weight-bold orange--text text--darken-1">{{selectedProduct.brandName}} {{selectedProduct.genericName}}</span>. Proceed?
                              </p>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="grey" text @click="closeDelete">Cancel</v-btn>
                              <v-btn color="orange darken-1" text @click="deleteItemConfirm">Proceed</v-btn>
                            </v-card-actions>
                          </v-card>
                      </v-dialog>
                      <!-- Dialog for edit product -->
                      <v-dialog v-model="editDialog"  max-width="720px" transition="dialog-bottom-transition" scrollable>
                        <v-sheet>
                          <v-app-bar
                            dark
                            color="orange darken-4">
                            <v-btn
                              icon
                              dark
                              @click="editDialog=false">
                              <v-icon>mdi-close</v-icon>
                            </v-btn>
                            <v-toolbar-title>Edit Product</v-toolbar-title>
                            <v-spacer></v-spacer>
                            <v-toolbar-items>
                              <v-btn
                                dark
                                text
                                @click="editProduct">
                                Update
                              </v-btn>
                            </v-toolbar-items>
                          </v-app-bar>
                          <v-container fluid>
                            <v-form
                                lazy-validation
                                ref="form">
                                <v-row>
                                  <v-col cols="12">
                                    <v-text-field color="orange darken-2" :rules="[rules.required]" v-model="editedProduct.sku" label="SKU"></v-text-field>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-text-field color="orange darken-2" :rules="[rules.required]" v-model="editedProduct.code" label="Product code" ></v-text-field>
                                  </v-col>
                                  <!-- <v-col cols="12" md="6">
                                    <v-text-field color="orange darken-2" clearable v-model="editedProduct.brandName" :rules="[rules.required]" label="Brand name"></v-text-field>
                                  </v-col> -->
                                  <v-col cols="12" md="6">
                                    <v-text-field color="orange darken-2" clearable v-model="editedProduct.genericName" :rules="[rules.required]" label="Generic name"></v-text-field>
                                  </v-col>
                                  <v-col cols="12">
                                    <v-textarea 
                                      v-model="description"
                                      color="orange darken-2"
                                      auto-grow
                                      counter="120"
                                      label="Description"></v-textarea>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                     <v-autocomplete
                                      v-model="editedProduct.category"
                                      :items="categories"
                                      auto-select-first
                                      item-text="name"
                                      single-line
                                      color="orange darken-2"
                                      placeholder="Category"
                                      >
                                    </v-autocomplete>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <v-text-field color="orange darken-2" clearable counter="10" v-model="editedProduct.unit" :rules="[rules.required]" label="Unit"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <v-text-field color="orange darken-2" :rules="[rules.required]" v-model="editedProduct.stock" type="number" label="No. of stocks"></v-text-field>
                                  </v-col>
                                  <v-col cols="12" md="6">
                                    <v-text-field color="orange darken-2" prefix="₱" :rules="[rules.required]" v-model="editedProduct.price" type="currency" label="Price"></v-text-field>
                                  </v-col>
                                  <!-- <v-col cols="12">
                                    <v-text-field color="orange darken-2"  v-model="expiry" label="Expiration date" type="date"></v-text-field>
                                  </v-col> -->
                                </v-row>
                            </v-form>
                          </v-container>
                        </v-sheet>
                      </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.stockActions="{item}">
                    <v-icon medium @click="addStock(item)" class="orange--text text--darken-4 mr-2">mdi-plus</v-icon>
                    <v-icon medium @click="deleteStock(item)" class="orange--text text--darken-4">mdi-minus</v-icon>
                    
                  </template>
                  <template v-slot:item.actions="{item}">
                    <v-icon medium @click="edit(item)" class="orange--text text--darken-4 mr-2">mdi-pencil</v-icon>
                    <v-icon medium @click="deleteProduct(item)" class="orange--text text--darken-4">mdi-delete</v-icon>
                  </template>
                  <template v-slot:item.price="{item}">
                    <span style="margin-right: 0.3em">PHP</span>
                    <span>{{item.price}}</span>
                  </template>
              </v-data-table>
          </v-col>
        </v-row>
       
      </v-container>
      <v-dialog v-model="stockDialog" max-width="480px" transition="dialog-bottom-transition">
        <v-sheet>
            <v-app-bar
              dark
              color="orange darken-4">
              <v-btn
                icon
                dark
                @click="stockDialog=false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{stockTitle}}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn
                  dark
                  text
                  @click="editStockQuantity">
                  Update
                </v-btn>
              </v-toolbar-items>
            </v-app-bar>
            <v-container fluid>
              <v-form>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field color="orange darken-2" v-model="editQuantity" label="Quantity"></v-text-field>
                    </v-col>
                  </v-row>
              </v-form>
            </v-container>
          </v-sheet>
      </v-dialog>
      <v-snackbar
        v-model="snackbar"
        timeout="4000"
        >
        {{snackbarText}}
      </v-snackbar>
    </v-app>
</template>

<script>
import {firebaseApp,fv} from "../firebase/firebaseInit";
let db = firebaseApp.firestore();
export default {
   components: {
  },
  data: () => ({
    dialog: false,
    editDialog: false,
    dialogDelete: false,
    stockDialog: false,
    stockTitle: "",
    isAdd: false,
    productID: "",
    selectedProduct: {},
    editedProduct: {},
    editQuantity: 1,
    rules: {
      required: value => !!value || 'Required.',
      counter: value => value.length <= 100 || 'Max 20 characters',
    },
    search: "",
    headers: [
      {text: "SKU", value: "sku"},
      {text: "Code", value: "code"},
      // {text: "Brand Name", value: "brandName"},
      {text: "Generic Name", value: "genericName"},
      {text: "Description", value: "description"},
      {text: "Category", value: "category"},
      {text: "Stock", value: "stock"},
      {text: "", value: "stockActions", sortable: false},
      {text: "Price (PHP)", value: "price", sortable: false},
      {text: "Unit", value:"unit", sortable: false},
      {text: "Actions", value: "actions", sortable: false},
    ],
    products:[],
    categories: [],
    snackbar: false,
    snackbarText: "",
    sku: "",
    code: "",
    brandName: "",
    genericName: "",
    description: "",
    category: "",
    unit: "",
    stock: 0,
    price: 0.00,
    expiry: null
  }),
  created(){
    db.collection('products').orderBy('stock','desc')
      .onSnapshot((querySnapshot) => {
        var items = [];
        querySnapshot.forEach((doc) => {
          const data = {
            productId: doc.id,
            sku: doc.data().sku,
            code: doc.data().code,
            brandName: doc.data().brandName,
            genericName: doc.data().genericName,
            description: doc.data().description,
            category: doc.data().category,
            unit: doc.data().unit,
            stock: parseInt(doc.data().stock),
            price: doc.data().price,
            expiry: doc.data().expiry
          };
          items.push(data);
        });
        this.products = items;
      })
    db.collection('portalData').doc('---portaldata---')
      .onSnapshot((docSnapshot) => {
        this.categories = docSnapshot.data().category;
      })
  },
  mounted(){
   
    
  },
  watch: {
    dialogDelete(val){
      val || this.closeDelete()
    },
    editDialog(val){
      val || this.closeEdit()
    }
  },
  computed: {
    hasAccess(){
      return this.$store.state.isAdmin;
    },
  },
  methods: {
    async editStockQuantity(){
      const docRef = db.collection('products').doc(this.selectedProduct.productId);
      var increment = parseInt(this.editQuantity);
      if(!this.isAdd){
        //make it negative
        increment = parseInt(this.editQuantity) * -1
      }
      docRef.update({
        stock: fv.increment(increment)
      }).then(() => {
        this.stockDialog = false;
        this.isAdd = false;
        this.editQuantity = 1;
        this.snackbar = true;
        this.snackbarText = "Product stock has been updated.";
      })
    },
    addStock(item){
      this.stockDialog = true;
      this.isAdd = true;
      this.selectedProduct = item;
      this.stockTitle = "Add Stocks"
    },
    deleteStock(item){
      this.stockDialog = true;
      this.isAdd = false;
      this.selectedProduct = item;
      this.stockTitle = "Remove Stocks"
    },
    async saveCategory(){
      const docRef = db.collection('portalData').doc('---portaldata---')
      await docRef.update('category', fv.arrayUnion(this.category));
    },
    async editProduct(){
        const ref = db.collection("products").doc(this.editedProduct.productId);
        this.editedProduct.stock = parseInt(this.editedProduct.stock);
        this.editedProduct.description = this.description
        await ref.set(this.editedProduct);
        this.editedProduct = {};
        this.editDialog = false;
    },
    async saveProduct(){
      var exists = this.categories.includes(this.category);
      if(!exists && this.categories != ""){
        await this.saveCategory();
      }
      const database = await db.collection("products").doc();
      await database.set({
          productId: database.id,
          sku: this.sku,
          code: this.code,
          brandName: this.brandName,
          genericName: this.genericName,
          description: this.description,
          category: this.category,
          unit: this.unit,
          stock: parseInt(this.stock),
          price: this.price,
          expiry: this.expiry
      });
      this.clear();
      this.dialog = false;
    },
    deleteProduct(item){
      this.productID = item.productId;
      this.selectedProduct = item;
      this.dialogDelete = true;
      // db.collection('products').doc(itemID).delete();
    },
    edit(item){
      this.editedProduct = item;
      this.editDialog = true;
    },
    async getProducts(){
      const database = await db.collection('products').orderBy('brandName','asc');
      const dbResults = await database.get();
      dbResults.forEach((doc) => {
        const data = {
          productId: doc.data().productId,
          sku: doc.data().sku,
          code: doc.data().code,
          brandName: doc.data().brandName,
          genericName: doc.data().genericName,
          description: doc.data().description,
          category: doc.data().category,
          unit: doc.data().unit,
          stock: doc.data().stock,
          price: doc.data().price,
          expiry: doc.data().expiry
        };
        this.products.push(data);
      })
      console.log(this.products);
    },
    deleteItemConfirm(){
      db.collection('products').doc(this.productID).delete();
      this.productID = ""
      this.closeDelete()
    },
    closeDelete(){
      this.productID = ""
      this.selectedProduct = {},
      this.dialogDelete = false
    },
    clear(){
      this.sku = "";
      this.code = "";
      this.brandName = "";
      this.genericName = "";
      this.description = "";
      this.category = "";
      this.unit = "";
      this.stock = 0;
      this.price = 0.00;
      this.expiry = null;
    }
  }
}
</script>

<style>

</style>