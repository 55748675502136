<template>
  <div class="login-container">
    <div class="login-form">
      <div class="form-container sign-in-container">
        <form action="#">
          <h1>Welcome!</h1>
          <span>Use your registered email and password</span>
          <input type="email" placeholder="Email" v-model="email" />
          <input type="password" placeholder="Password" v-model="password" />
          <router-link :to="{name: 'ForgotPassword'}" class="orange--text text--darken-4"> Forgot your password? Click to reset</router-link>
          <v-fab-transition>
            <button @click.prevent="signIn">Sign In</button>
          </v-fab-transition>
          <p class="error-message">
            {{this.errorMessage}}
          </p>
        </form>
      </div>
      <div class="overlay-container">
        <div class="overlay">
          <div class="overlay-panel overlay-right">
            <svg viewBox="0 0 128 128" class="logo">
                <g transform="translate(-1074.394 -1142.922)">
                    <path id="Path_7" class="st0" d="M1157.66,1195.83l-0.03,22.23l-19.26,11.09l-19.24-11.13l0.03-22.23l19.26-11.09L1157.66,1195.83z
                        "></path>
                    <path id="Path_8" class="st0" d="M1190.95,1176.66l-0.02,20.62l-18.12,13.26l0.03-23.45l-24.41-14.13l-17.62-22.28l7.66-4.41
                        L1190.95,1176.66z"></path>
                    <path id="Path_9" class="st0" d="M1190.93,1197.28l-0.04,40.03l-19.28,11.1l-9.04-15.69l10.22-5.89l0.02-16.29L1190.93,1197.28z"></path>
                    <path id="Path_10" class="st0" d="M1149.44,1240.27l9.04,15.69l-16.1,9.27l-15.35-25.13l11.32,6.55L1149.44,1240.27z"></path>
                    <path id="Path_11" class="st0" d="M1130.81,1150.69l17.62,22.28l-9.98-5.78l-34.44,19.83l-0.03,22.01l0,0l-18.12,12.95l0.05-45.44
                        L1130.81,1150.69z"></path>
                    <path id="Path_12" class="st0" d="M1127.03,1240.11l15.35,25.13l-4.06,2.33l-52.49-30.38l0.02-15.21l18.12-12.95l0,0l-0.02,17.72
                        L1127.03,1240.11L1127.03,1240.11z"></path>
                </g>
            </svg>
            <h3>Queacon Pharmaceuticals Inc.</h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import firebase from "../firebase/firebaseInit";
  import "firebase/auth";
  export default {
    name: 'Home',
    data : () => ({
      email: "",
      password: "",
      error: "",
      errorMessage: "",
    }),
    methods: {
      signIn() {
        if(this.email == ""){
          this.error = true;
          this.errorMessage = "Email address cannot be blank."
          return;
        }
        if(this.password == ""){
          this.error = true;
          this.errorMessage = "Password cannot be blank."
          return;
        }
        firebase
          .auth()
          .signInWithEmailAndPassword(this.email, this.password)
          .then(() => {
            this.$store.dispatch('getCurrentUser').then(() =>{
              this.$router.go();
            })
            this.error = false;
            this.errorMessage = "";
          })
          .catch((err) => {
            this.error = true;
            this.errorMessage = err.message;
          });
      }
    }
  }
</script>
<style lang="scss" scoped>
  @font-face {
    font-family: 'Montserrat';
    src: url('https://fonts.googleapis.com/css?family=Montserrat:400,800');
  }
  .logo{
    fill: #ffffff;
    width: 120px;
    height: 120px;
    margin: 0 0 10px 0;
  }
  .error-message{
    color: #f55c47;
    font-weight: 500;
  }
  .login-container{
    height: 100%;
    display: flex;
    justify-content: center;
    background-color: #EAEAEA;
  }
  .login-form{
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
        0 10px 10px rgba(0,0,0,0.22);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
    margin: auto;
  }
  h1 {
    font-weight: bold;
    font-family: 'Montserrat', sans-serif;
    margin: 0;
  }

  h2 {
    text-align: center;
  }

  p {
    font-size: 14px;
    font-weight: 100;
    line-height: 20px;
    letter-spacing: 0.5px;
    margin: 20px 0 30px;
  }

  span {
    font-size: 12px;
  }

  a {
    color: #333;
    font-size: 14px;
    text-decoration: none;
    margin: 15px 0;
  }

  button {
    border-radius: 30px;
    border: 1px solid #fc5404;
    background-color: #fc5404;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
  }

  button:active {
    transform: scale(0.95);
  }

  button:focus {
    outline: none;
  }

  button.ghost {
    background-color: transparent;
    border-color: #FFFFFF;
  }

  form {
    background-color: #FFFFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 50px;
    height: 100%;
    text-align: center;
  }

  input {
    background-color: #eee;
    border: none;
    padding: 12px 15px;
    margin: 8px 0;
    width: 100%;
  }

  .container {
    background-color: #fff;
    border-radius: 10px;
      box-shadow: 0 14px 28px rgba(0,0,0,0.25), 
        0 10px 10px rgba(0,0,0,0.22);
    position: relative;
    overflow: hidden;
    width: 768px;
    max-width: 100%;
    min-height: 480px;
  }

  .form-container {
    position: absolute;
    top: 0;
    height: 100%;
    transition: all 0.6s ease-in-out;
  }

  .sign-in-container {
    left: 0;
    width: 50%;
    z-index: 2;
    h1{
      color :#fc5404;
    }
    span{
      margin: 10px 0;
    }
  }

  .container.right-panel-active .sign-in-container {
    transform: translateX(100%);
  }

  .sign-up-container {
    left: 0;
    width: 50%;
    opacity: 0;
    z-index: 1;
  }

  .container.right-panel-active .sign-up-container {
    transform: translateX(100%);
    opacity: 1;
    z-index: 5;
    animation: show 0.6s;
  }

  @keyframes show {
    0%, 49.99% {
      opacity: 0;
      z-index: 1;
    }
    
    50%, 100% {
      opacity: 1;
      z-index: 5;
    }
  }

  .overlay-container {
    position: absolute;
    top: 0;
    left: 50%;
    width: 50%;
    height: 100%;
    overflow: hidden;
    transition: transform 0.6s ease-in-out;
    z-index: 100;
  }

  .container.right-panel-active .overlay-container{
    transform: translateX(-100%);
  }

  .overlay {
    background: #fc5404;
    background: -webkit-linear-gradient(#f9b208, #fc5404);
    background: linear-gradient(#f9b208, #fc5404);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 0 0;
    color: #FFFFFF;
    position: relative;
    left: -100%;
    height: 100%;
    width: 200%;
      transform: translateX(0);
    transition: transform 0.6s ease-in-out;
  }

  .container.right-panel-active .overlay {
      transform: translateX(50%);
  }

  .overlay-panel {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 0 40px;
    text-align: center;
    top: 0;
    height: 100%;
    width: 50%;
    transform: translateX(0);
    transition: transform 0.6s ease-in-out;
  }

  .overlay-left {
    transform: translateX(-20%);
  }

  .container.right-panel-active .overlay-left {
    transform: translateX(0);
  }

  .overlay-right {
    right: 0;
    transform: translateX(0);
  }

  .container.right-panel-active .overlay-right {
    transform: translateX(20%);
  }

  .social-container {
    margin: 20px 0;
  }

  .social-container a {
    border: 1px solid #DDDDDD;
    border-radius: 50%;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    height: 40px;
    width: 40px;
  }
</style>
