import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
    apiKey: "AIzaSyCpcRuQC8Pz49r27bIBpmqXs7Rt6gR8a-A",
    authDomain: "quaeconportal-b87e0.firebaseapp.com",
    projectId: "quaeconportal-b87e0",
    storageBucket: "quaeconportal-b87e0.appspot.com",
    messagingSenderId: "977205864982",
    appId: "1:977205864982:web:75e05b0255e74ee83a2337",
    measurementId: "G-23WR0TML6G"
};
// const firebaseConfig = {
//   apiKey: "AIzaSyDiwekyuZiP5BKOHdI_8tIc8oIAMRiOqBg",
//   authDomain: "qpi-portal-test.firebaseapp.com",
//   projectId: "qpi-portal-test",
//   storageBucket: "qpi-portal-test.appspot.com",
//   messagingSenderId: "536592654779",
//   appId: "1:536592654779:web:97752260412f33d309e49e",
//   measurementId: "G-BN45ZH6V6N"
// };

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig)
const fv = firebase.firestore.FieldValue;

export {firebaseApp, fv};
export default firebaseApp;