<template>
    <div>
        <v-navigation-drawer permanent expand-on-hover app class="grey lighten-3" disable-resize-watcher>
            <v-list>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="text-h6 font-weight-black mb-2">
                            QPI Portal Dashboard
                        </v-list-item-title>
                        <v-list-item-subtitle>
                            <v-icon color="orange darken-4">
                                mdi-account-circle
                            </v-icon> 
                            <span class="orange--text text--darken-4">
                                {{fullName}}
                            </span>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list dense nav>
                <v-list-item link :to="{name: 'Dashboard'}" active-class="orange darken-4 white--text">
                    <v-list-item-icon>
                        <v-icon>home</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            Dashboard
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item  v-if="this.$store.state.isAdmin" link :to="{name: 'Products'}" active-class="orange darken-4 white--text">
                    <v-list-item-icon>
                        <v-icon>view_list</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            Products
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item link :to="{name: 'Order'}" active-class="orange darken-4 white--text">
                    <v-list-item-icon>
                        <v-icon>archive</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            Ordering
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                  <v-list-item  v-if="this.$store.state.isAdmin"  link :to="{name: 'Users'}" active-class="orange darken-4 white--text">
                    <v-list-item-icon>
                        <v-icon>mdi-account</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>
                            Users
                        </v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-spacer></v-spacer>
                <v-list-item link @click="logOut">
                    <v-list-item-icon>
                        <v-icon>exit_to_app</v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>Logout</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </div>
    
</template>
<script>
import firebase from '../firebase/firebaseInit';
import "firebase/auth";
export default {
    name: 'navigation',
    data : () => ({
        drawer: true,
        dialog: false,
    }),
    computed: {
        fullName() {
            var name = "";
            if(this.$store.state.user != null){
                name = this.$store.state.user.firstName + ' ' + this.$store.state.user.lastName;
            }
            return name;
        }
    },
    mounted(){
    },
    methods: {
        logOut(){
            firebase.auth().signOut().then(() => {
                this.$router.go();
            });
        }
    }
};
</script>
<style lang="scss">
</style>